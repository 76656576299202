import { Box, Button, TextField } from '@mui/material'
import { Formik } from 'formik'
import * as yup from 'yup'
import useMediaQuery from '@mui/material/useMediaQuery'
import Header from '../../components/Header'
import axios from 'axios'
import React from 'react'
import { DEVICE_API, ERROR_MSG } from '../../environment'

const DeviceForm = () => {
  const isNonMobile = useMediaQuery('(min-width:600px)')

  const handleFormSubmit = (values) => {
    // console.log(values);
    axios
      .post(DEVICE_API, values)
      .then((response) => {
        // console.log(response);
        alert('Device onboarded successfully!!!')
      })
      .catch((error) => {
        // console.log(error);
        alert(
          error.response && typeof error.response.data.message === 'string'
            ? error.response.data.message
            : ERROR_MSG,
        )
      })
  }

  return (
    <Box m='50px'>
      <Header title='IoT Device Onboarding Form' subtitle='Onboard a new device' />

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display='grid'
              gap='30px'
              gridTemplateColumns='repeat(4, minmax(0, 1fr))'
              sx={{
                '& > div': { gridColumn: isNonMobile ? undefined : 'span 4' },
              }}
            >
              <TextField
                fullWidth
                variant='filled'
                type='text'
                label='Device ID'
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.device_id}
                name='device_id'
                error={!!touched.device_id && !!errors.device_id}
                helperText={touched.device_id && errors.device_id}
                sx={{ gridColumn: 'span 2' }}
              />
            </Box>
            <Box display='flex' justifyContent='center' mt='80px'>
              <Button type='submit' color='secondary' variant='contained' size='large'>
                SUBMIT
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  )
}

const checkoutSchema = yup.object().shape({
  device_id: yup.string().required('required'),
})

const initialValues = {
  device_id: '',
}

export default DeviceForm
