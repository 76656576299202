export const UnauthorizedPage = function () {
  return (
    <div style={{ textAlign: 'center', paddingTop: '15%' }}>
      <h1>You are not authorized to view this page!</h1>
    </div>
  )
}

export const PageNotFound = function () {
  return (
    <div style={{ textAlign: 'center', paddingTop: '15%' }}>
      <h1>404</h1>
      <h1>Page Not Found</h1>
      <h2>Sorry, but we can't find the page you are looking for...</h2>
    </div>
  )
}
