import { Box, Button, TextField } from '@mui/material'
import { Formik } from 'formik'
import * as yup from 'yup'
import useMediaQuery from '@mui/material/useMediaQuery'
import Header from '../../components/Header'
import axios from 'axios'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import React, { useEffect, useState } from 'react'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker, DateTimePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import { DEVICE_UNREGISTERED, ERROR_MSG, ONBOARD_DG_API, USER_ALL_API } from '../../environment'

const DgForm = () => {
  const isNonMobile = useMediaQuery('(min-width:600px)')
  const [users, setUsers] = useState([])
  const [devices, setDevices] = useState([])
  const [userDropdownOpen, setUserDropdownOpen] = useState(false)
  const [deviceDropdownOpen, setDeviceDropdownOpen] = useState(false)

  useEffect(() => {
    axios
      .get(USER_ALL_API)
      .then((response) => {
        setUsers(response.data.data)
        // console.log("----GET USERS-----", response.data);
      })
      .catch((error) => {
        alert(ERROR_MSG)
      })
  }, [])

  useEffect(() => {
    axios
      .get(DEVICE_UNREGISTERED)
      .then((response) => {
        setDevices(response.data.data)
        // console.log("----GET DEVICES-----", response.data);
      })
      .catch((error) => {
        alert(ERROR_MSG)
      })
  }, [])

  const handleUserDropdownClose = () => {
    setUserDropdownOpen(false)
  }
  const handleUserDropdownOpen = () => {
    setUserDropdownOpen(true)
  }

  const handleDeviceDropdownClose = () => {
    setDeviceDropdownOpen(false)
  }
  const handleDeviceDropdownOpen = () => {
    setDeviceDropdownOpen(true)
  }

  const handleFormSubmit = (values) => {
    const formattedMfgDate = dayjs(values.mfg_date).format('YYYY-MM-DDTHH:mm:ssZ')
    const formattedLastServiceDate = dayjs(values.last_service_date).format('YYYY-MM-DDTHH:mm:ssZ')
    const formattedInstallationDate = dayjs(values.installation_date).format('YYYY-MM-DDTHH:mm:ssZ')

    const data = {
      user_id: values.user_id,
      device_id: values.device_id,
      name: values.name,
      model: values.model,
      controller: values.controller,
      engine_serial_number: values.engine_serial_number,
      kVA_rating: parseFloat(values.kVA_rating),
      ert_before_installation: parseFloat(values.ert_before_installation),
      ert_after_last_maintenance: parseFloat(values.ert_after_last_maintenance),
      fuel_sensor_height: parseFloat(values.fuel_sensor_height),
      fuel_tank_cross_section: parseFloat(values.fuel_tank_cross_section),
      fuel_tank_height: parseFloat(values.fuel_tank_height),
      latitude: parseFloat(values.latitude),
      longitude: parseFloat(values.longitude),
      last_service_date: formattedLastServiceDate,
      installation_date: formattedInstallationDate,
      lubricant_oil_pressure_m: parseFloat(values.lubricant_oil_pressure_m),
      lubricant_oil_pressure_c: parseFloat(values.lubricant_oil_pressure_c),
      mfg_date: formattedMfgDate,
      water_temp_signal_m: parseFloat(values.water_temp_signal_m),
      water_temp_signal_c: parseFloat(values.water_temp_signal_c),
      rpm_m: parseFloat(values.rpm_m),
      rpm_c: parseFloat(values.rpm_c),
      notes: values.notes,
    }

    axios
      .post(ONBOARD_DG_API, data)
      .then((response) => {
        // console.log(response);
        alert('DG onboarded successfully!!!')
      })
      .catch((error) => {
        // console.log(error);
        alert(
          error.response && typeof error.response.data.message === 'string'
            ? error.response.data.message
            : ERROR_MSG,
        )
      })
  }

  return (
    <Box m='50px'>
      <Header title='DG Onboarding Form' subtitle='Onboard a new DG' />

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
        validate={validate}
      >
        {({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display='grid'
              gap='30px'
              gridTemplateColumns='repeat(4, minmax(0, 1fr))'
              sx={{
                '& > div': { gridColumn: isNonMobile ? undefined : 'span 4' },
              }}
            >
              <FormControl variant='filled' sx={{ gridColumn: 'span 2' }}>
                <InputLabel id='simple-select-label'>User Name</InputLabel>
                <Select
                  labelId='simple-select-label'
                  id='simple-select'
                  name='user_id'
                  value={values.user_id}
                  label='User Name'
                  onChange={handleChange}
                  open={userDropdownOpen}
                  onClose={handleUserDropdownClose}
                  onOpen={handleUserDropdownOpen}
                  required
                >
                  {users && Object.entries(users).length > 0 ? (
                    users.map((user) => (
                      <MenuItem key={user.user_id} value={user.user_id}>
                        {user.name ? user.name : user.user_id}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem value=''>None</MenuItem>
                  )}
                </Select>
              </FormControl>
              <FormControl variant='filled' sx={{ gridColumn: 'span 2' }}>
                <InputLabel id='simple-select-label'>Device ID</InputLabel>
                <Select
                  labelId='simple-select-label'
                  id='simple-select'
                  name='device_id'
                  value={values.device_id}
                  label='Device ID'
                  onChange={handleChange}
                  open={deviceDropdownOpen}
                  onClose={handleDeviceDropdownClose}
                  onOpen={handleDeviceDropdownOpen}
                  required
                >
                  {devices && Object.entries(devices).length > 0 ? (
                    devices.map((device) => (
                      <MenuItem key={device.device_id} value={device.device_id}>
                        {device.device_id}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem value=''>None</MenuItem>
                  )}
                </Select>
              </FormControl>
              <TextField
                fullWidth
                variant='filled'
                type='text'
                label='Name'
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.name}
                name='name'
                error={!!touched.name && !!errors.name}
                helperText={touched.name && errors.name}
                sx={{ gridColumn: 'span 2' }}
              />
              <TextField
                fullWidth
                variant='filled'
                type='text'
                label='Model'
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.model}
                name='model'
                error={!!touched.model && !!errors.model}
                helperText={touched.model && errors.model}
                sx={{ gridColumn: 'span 2' }}
              />
              <TextField
                fullWidth
                variant='filled'
                type='text'
                label='Controller'
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.controller}
                name='controller'
                error={!!touched.controller && !!errors.controller}
                helperText={touched.controller && errors.controller}
                sx={{ gridColumn: 'span 2' }}
              />
              <TextField
                fullWidth
                variant='filled'
                type='text'
                label='Engine Serial Number (ESN)'
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.engine_serial_number}
                name='engine_serial_number'
                error={!!touched.engine_serial_number && !!errors.engine_serial_number}
                helperText={touched.engine_serial_number && errors.engine_serial_number}
                sx={{ gridColumn: 'span 2' }}
              />
              <TextField
                fullWidth
                variant='filled'
                type='text'
                label='Total run time after last maintenance (in hrs)'
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.ert_after_last_maintenance}
                name='ert_after_last_maintenance'
                error={!!touched.ert_after_last_maintenance && !!errors.ert_after_last_maintenance}
                helperText={touched.ert_after_last_maintenance && errors.ert_after_last_maintenance}
                sx={{ gridColumn: 'span 2' }}
              />
              <TextField
                fullWidth
                variant='filled'
                type='text'
                label='Fuel sensor height (in mm)'
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.fuel_sensor_height}
                name='fuel_sensor_height'
                error={!!touched.fuel_sensor_height && !!errors.fuel_sensor_height}
                helperText={touched.fuel_sensor_height && errors.fuel_sensor_height}
                sx={{ gridColumn: 'span 2' }}
              />
              <TextField
                fullWidth
                variant='filled'
                type='text'
                label='Fuel tank cross section (in mm2)'
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.fuel_tank_cross_section}
                name='fuel_tank_cross_section'
                error={!!touched.fuel_tank_cross_section && !!errors.fuel_tank_cross_section}
                helperText={touched.fuel_tank_cross_section && errors.fuel_tank_cross_section}
                sx={{ gridColumn: 'span 2' }}
              />
              <TextField
                fullWidth
                variant='filled'
                type='text'
                label='Fuel tank height (in mm)'
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.fuel_tank_height}
                name='fuel_tank_height'
                error={!!touched.fuel_tank_height && !!errors.fuel_tank_height}
                helperText={touched.fuel_tank_height && errors.fuel_tank_height}
                sx={{ gridColumn: 'span 2' }}
              />
              <TextField
                fullWidth
                variant='filled'
                type='text'
                label='Latitude'
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.latitude}
                name='latitude'
                error={!!touched.latitude && !!errors.latitude}
                helperText={touched.latitude && errors.latitude}
                sx={{ gridColumn: 'span 2' }}
              />
              <TextField
                fullWidth
                variant='filled'
                type='text'
                label='Longitude'
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.longitude}
                name='longitude'
                error={!!touched.longitude && !!errors.longitude}
                helperText={touched.longitude && errors.longitude}
                sx={{ gridColumn: 'span 2' }}
              />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label='Date of Manufacture'
                  inputFormat='DD/MM/YYYY'
                  value={values.mfg_date || ''}
                  onChange={(date) => setFieldValue('mfg_date', date)}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      variant='filled'
                      {...params}
                      error={touched.mfg_date && errors.mfg_date}
                      helperText={touched.mfg_date && errors.mfg_date}
                    />
                  )}
                  sx={{ gridColumn: 'span 2' }}
                />
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  label='Last servicing date'
                  inputFormat='DD/MM/YYYY HH:mm'
                  value={values.last_service_date || ''}
                  onChange={(date) => setFieldValue('last_service_date', date)}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      variant='filled'
                      {...params}
                      error={touched.last_service_date && errors.last_service_date}
                      helperText={touched.last_service_date && errors.last_service_date}
                    />
                  )}
                  sx={{ gridColumn: 'span 2' }}
                />
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  label='Installation date'
                  inputFormat='DD/MM/YYYY HH:mm'
                  value={values.installation_date || ''}
                  onChange={(date) => setFieldValue('installation_date', date)}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      variant='filled'
                      {...params}
                      error={touched.installation_date && errors.installation_date}
                      helperText={touched.installation_date && errors.installation_date}
                    />
                  )}
                  sx={{ gridColumn: 'span 2' }}
                />
              </LocalizationProvider>
              <TextField
                fullWidth
                variant='filled'
                type='text'
                label='Total run time before installation (in hrs)'
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.ert_before_installation}
                name='ert_before_installation'
                error={!!touched.ert_before_installation && !!errors.ert_before_installation}
                helperText={touched.ert_before_installation && errors.ert_before_installation}
                sx={{ gridColumn: 'span 2' }}
              />
              <Box display='flex' gap='50px'>
                <TextField
                  fullWidth
                  variant='filled'
                  type='text'
                  label='Enter m for Lubricant Oil Pressure'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.lubricant_oil_pressure_m}
                  name='lubricant_oil_pressure_m'
                  error={!!touched.lubricant_oil_pressure_m && !!errors.lubricant_oil_pressure_m}
                  helperText={touched.lubricant_oil_pressure_m && errors.lubricant_oil_pressure_m}
                  sx={{ gridColumn: 'span 2' }}
                />
              </Box>
              <Box display='flex' gap='50px'>
                <TextField
                  fullWidth
                  variant='filled'
                  type='text'
                  label='Enter c for Lubricant Oil Pressure'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.lubricant_oil_pressure_c}
                  name='lubricant_oil_pressure_c'
                  error={!!touched.lubricant_oil_pressure_c && !!errors.lubricant_oil_pressure_c}
                  helperText={touched.lubricant_oil_pressure_c && errors.lubricant_oil_pressure_c}
                  sx={{ gridColumn: 'span 2' }}
                />
              </Box>
              <Box display='flex' gap='50px'>
                <TextField
                  fullWidth
                  variant='filled'
                  type='text'
                  label='Enter m for water temprature'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.water_temp_signal_m}
                  name='water_temp_signal_m'
                  error={!!touched.water_temp_signal_m && !!errors.water_temp_signal_m}
                  helperText={touched.water_temp_signal_m && errors.water_temp_signal_m}
                  sx={{ gridColumn: 'span 2' }}
                />
              </Box>
              <Box display='flex' gap='50px'>
                <TextField
                  fullWidth
                  variant='filled'
                  type='text'
                  label='Enter c for water temprature'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.water_temp_signal_c}
                  name='water_temp_signal_c'
                  error={!!touched.water_temp_signal_c && !!errors.water_temp_signal_c}
                  helperText={touched.water_temp_signal_c && errors.water_temp_signal_c}
                  sx={{ gridColumn: 'span 2' }}
                />
              </Box>
              <Box display='flex' gap='50px'>
                <TextField
                  fullWidth
                  variant='filled'
                  type='text'
                  label='Enter m for RPM'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.rpm_m}
                  name='rpm_m'
                  error={!!touched.rpm_m && !!errors.rpm_m}
                  helperText={touched.rpm_m && errors.rpm_m}
                  sx={{ gridColumn: 'span 2' }}
                />
              </Box>
              <Box display='flex' gap='50px'>
                <TextField
                  fullWidth
                  variant='filled'
                  type='text'
                  label='Enter c for RPM'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.rpm_c}
                  name='rpm_c'
                  error={!!touched.rpm_c && !!errors.rpm_c}
                  helperText={touched.rpm_c && errors.rpm_c}
                  sx={{ gridColumn: 'span 2' }}
                />
              </Box>
              <TextField
                fullWidth
                variant='filled'
                type='text'
                label='kVA Rating'
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.kVA_rating}
                name='kVA_rating'
                error={!!touched.kVA_rating && !!errors.kVA_rating}
                helperText={touched.kVA_rating && errors.kVA_rating}
                sx={{ gridColumn: 'span 2' }}
              />
              <TextField
                fullWidth
                variant='filled'
                type='text'
                label='Notes'
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.notes}
                name='notes'
                error={!!touched.notes && !!errors.notes}
                helperText={touched.notes && errors.notes}
                sx={{ gridColumn: 'span 2' }}
              />
            </Box>
            <Box display='flex' justifyContent='center' mt='80px'>
              <Button type='submit' color='secondary' variant='contained' size='large'>
                SUBMIT
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  )
}

const checkoutSchema = yup.object().shape({
  user_id: yup.string().required('required'),
  device_id: yup.string().required('required'),
  name: yup.string().required('required'),
  model: yup.string().required('required'),
  engine_serial_number: yup.string(),
  kVA_rating: yup.number().required('required'),
  ert_after_last_maintenance: yup.number(),
  fuel_tank_cross_section: yup.number().required('required'),
  fuel_tank_height: yup.number().required('required'),
  controller: yup.string().required('required'),
  ert_before_installation: yup.number(),
  notes: yup.string(),
  latitude: yup.number(),
  longitude: yup.number(),
  lubricant_oil_pressure_m: yup.number(),
  lubricant_oil_pressure_c: yup.number(),
  water_temp_signal_m: yup.number(),
  water_temp_signal_c: yup.number(),
  rpm_m: yup.number(),
  rpm_c: yup.number(),
  installation_date: yup.date().required('required'),
})
const initialValues = {
  user_id: '',
  device_id: '',
  name: '',
  model: '',
  engine_serial_number: '',
  kVA_rating: '',
  ert_after_last_maintenance: '',
  fuel_tank_cross_section: '',
  fuel_tank_height: '',
  controller: '',
  ert_before_installation: '',
  notes: '',
  latitude: '',
  longitude: '',
}
const validate = (values, props) => {
  const errors = {}
  if (
    !(!values.lubricant_oil_pressure_m && !values.lubricant_oil_pressure_c) &&
    !(values.lubricant_oil_pressure_m && values.lubricant_oil_pressure_c)
  ) {
    errors.lubricant_oil_pressure_c = values.lubricant_oil_pressure_c
      ? ''
      : 'm and c both value required for Lubricant Oil Pressure'
    errors.lubricant_oil_pressure_m = values.lubricant_oil_pressure_m
      ? ''
      : 'm and c both value required for Lubricant Oil Pressure'
  }
  if (
    !(!values.water_temp_signal_m && !values.water_temp_signal_c) &&
    !(values.water_temp_signal_m && values.water_temp_signal_c)
  ) {
    errors.water_temp_signal_c = values.water_temp_signal_c
      ? ''
      : 'm and c both value required for water temprature'
    errors.water_temp_signal_m = values.water_temp_signal_m
      ? ''
      : 'm and c both value required for water temprature'
  }
  if (!(!values.rpm_m && !values.rpm_c) && !(values.rpm_m && values.rpm_c)) {
    errors.rpm_m = values.rpm_m ? '' : 'm and c both value required for RPM'
    errors.rpm_c = values.rpm_c ? '' : 'm and c both value required for RPM'
  }

  return errors
}

export default DgForm
