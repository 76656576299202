import { useMediaQuery, useTheme } from '@mui/material'
import calculateStyles from './style'

const RefundPolicy = () => {
  const theme = useTheme()
  const smScreen = useMediaQuery(theme.breakpoints.up('sm'))
  const indexStyle = calculateStyles(smScreen)
  return (
    <div style={indexStyle.container}>
      <div style={indexStyle.header}>
        <h1 style={indexStyle.mainheading}>REFUND POLICY</h1>
        <p style={indexStyle.subheading1}>We do not have a refund policy at present.</p>
      </div>
    </div>
  )
}

export default RefundPolicy
