import { Box, Button, TableContainer, Typography, useTheme, useMediaQuery } from '@mui/material'
import { tokens } from '../theme'
import { useState, React } from 'react'
import { styled } from '@mui/material/styles'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableBody from '@mui/material/TableBody'
import TablePagination from '@mui/material/TablePagination'
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined'
import * as XLSX from 'xlsx'

const MuiTable = ({ dg, rows }) => {
  const theme = useTheme()
  const colors = tokens(theme.palette.mode)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const smScreen = useMediaQuery(theme.breakpoints.up('sm'))

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const createExcelFile = () => {
    const data = []
    for (const row of rows) {
      const item = {
        S_No: row.sNo,
        Date: row.date,
        Start_Time: row.startTime,
        Stop_Time: row.stopTime,
        Running_Duration: row.runningDuration,
        Units_kVAh: row.unitsVah,
      }
      data.push(item)
    }

    const workbook = XLSX.utils.book_new()
    const worksheet = XLSX.utils.json_to_sheet(data)
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Running History')
    XLSX.writeFile(workbook, dg + '.xlsx')
  }

  return (
    <div
      style={{
        color: 'white',
        lineHeight: 10,
        paddingTop: 30,
      }}
    >
      <Box backgroundColor={colors.primary[400]} sx={{ borderRadius: '16px' }}>
        <Box
          display='flex'
          flexDirection={smScreen ? 'row' : 'column'}
          alignItems='center'
          justifyContent='center'
          textAlign='center'
        >
          <Box marginLeft={smScreen ? '35%' : '0%'} paddingTop={smScreen ? '0%' : '2%'}>
            <Typography variant='h2' textAlign='center' color={colors.grey[100]} fontWeight='bold'>
              Runtime Logs
            </Typography>
          </Box>
          <Box marginLeft={smScreen ? '35%' : '0%'}>
            <Button
              sx={{
                backgroundColor: colors.blueAccent[600],
                color: colors.grey[100],
                fontSize: '12px',
                fontWeight: 'bold',
                padding: '8px',
                ':hover': {
                  bgcolor: colors.blueAccent[700],
                  color: colors.grey[100],
                },
              }}
              onClick={createExcelFile}
            >
              <DownloadOutlinedIcon sx={{ mr: '10px' }} />
              Download
            </Button>
          </Box>
        </Box>
        <TableContainer sx={{ maxWidth: '100%', overflowX: 'auto' }}>
          <Table>
            <TableHead>
              <TableRow style={{ color: theme.palette.mode.white }}>
                <StyledTableCell align='center' className='Table-cell-100'>
                  S No.
                </StyledTableCell>
                <StyledTableCell align='center' className='Table-cell'>
                  Date
                </StyledTableCell>
                <StyledTableCell align='center' className='Table-cell'>
                  Start Time
                </StyledTableCell>
                <StyledTableCell align='center' className='Table-cell'>
                  Stop Time
                </StyledTableCell>
                <StyledTableCell align='center' className='Table-cell'>
                  Running Duration
                </StyledTableCell>
                <StyledTableCell align='center' className='Table-cell'>
                  Units (kVAh)
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                <TableRow key={row.sNo}>
                  <StyledTableCell align='center' className='Table-cell-100'>
                    {' '}
                    {row.sNo}
                  </StyledTableCell>
                  <StyledTableCell align='center' className='Table-cell'>
                    {' '}
                    {row.date}
                  </StyledTableCell>
                  <StyledTableCell align='center' className='Table-cell'>
                    {' '}
                    {row.startTime}
                  </StyledTableCell>
                  <StyledTableCell align='center' className='Table-cell'>
                    {row.stopTime}
                  </StyledTableCell>
                  <StyledTableCell align='center' className='Table-cell'>
                    {row.runningDuration}
                  </StyledTableCell>
                  <StyledTableCell align='center' className='Table-cell'>
                    {row.unitsVah}
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={smScreen ? [5, 10, 20] : []}
            component='div'
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </Box>
    </div>
  )
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    fontSize: 15,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}))

export default MuiTable
