import { useMediaQuery, useTheme } from '@mui/material'
import calculateStyles from '../refund-policy/style'

const AboutUs = () => {
  const theme = useTheme()
  const smScreen = useMediaQuery(theme.breakpoints.up('sm'))
  const indexStyle = calculateStyles(smScreen)

  return (
    <div style={indexStyle.container}>
      <div style={indexStyle.header}>
        <h1 style={indexStyle.mainheading}>ABOUT US</h1>
      </div>

      <div>
        <h2 style={indexStyle.subheading}>Who are we?</h2>
        <p style={indexStyle.para}>
          Chakr Innovation is a cleantech startup based in India specializing in material science
          technology. The company was founded by graduates from IIT Delhi and works towards creating
          sustainable and environmentally friendly products that help improve air quality and reduce
          carbon footprint. Chakr Innovation is the first company in India to receive type approval
          certification for their Retrofit Emission Control Device for DG sets (RECD) from Central
          Pollution Control Board of India (CPCB) accredited labs. Chakr has over 15 patents filed,
          and our work has been recognized across the globe by reputed organizations like United
          Nations, WWF, and Forbes.
        </p>
        <h2 style={indexStyle.subheading}>Our inspiration and its genesis</h2>
        <p style={indexStyle.para}>
          Chakr Innovation was founded by graduates from Indian Institute of Technology Delhi in
          2016 to reduce pollution with the help of innovation and technology. The idea began with a
          group of friends having sugarcane juice at a shop with a wall turned black because of soot
          particles coming out from the diesel generator exhaust used for crushing sugarcane. Chakr
          Innovation launched Chakr Shield in 2017, one year after its incorporation. The device
          reduces the Particulate Matter 2.5 (PM 2.5) emission from a diesel generator by over 70%.
          In 2022 the company introduced a Dual Fuel Kit that allows a diesel generator to run on
          fossil fuel and Natural gas simultaneously in a 30 to 70 ratio. The company has
          established its corporate office in Delhi-NCR while its manufacturing unit is in Pune.
        </p>

        <h2 style={indexStyle.subheading}>Core offerings of Chakr Innovation (Our USP)</h2>
        <ol type='a'>
          <li style={indexStyle.para}>
            {' '}
            Chakr Shield is a patented retrofit emission control device (RECD), which reduces
            Particulate Matter, Hydrocarbons, and Carbon monoxide emissions from diesel generator
            sets by over 70%. Our technology is also type-approved by the International Centre for
            Automotive Technology (ICAT) Manesar and the Automotive Research Association of India
            (ARAI) Pune, among the 4 national labs recognized by CPCB and the Ministry of
            Environment, Forest, and Climate Change. We are the first technology in India to have
            completed this approval successfully. We have successfully worked with leading companies
            in India, including HPCL, India Oil Corporation, Amazon, Reliance Industries and
            Kirloskar Oil Engines Limited and have over 500 emission control devices successfully
            commissioned.
          </li>

          <li style={indexStyle.para}>
            {' '}
            The Chakr Dual Fuel Kit uses technology to allow a diesel generator set to operate on a
            mixture of gas and diesel as a fuel, with 70% natural gas and 30% fossil fuel. With the
            launch of this product, Chakr Innovation reportedly became the only turnkey solution
            provider in India to control the emissions from diesel generators.
          </li>
        </ol>
        <h2 style={indexStyle.subheading}>Awards and recognition:</h2>
        <ol type='a'>
          <li style={indexStyle.para}> 2018: 30 Under 30 Social Entrepreneurs – Forbes</li>
          <li style={indexStyle.para}> 2017: Champions of Change - NITI Aayog </li>
          <li style={indexStyle.para}> 2017: Echoing Green Fellowship </li>
          <li style={indexStyle.para}>
            {' '}
            2017: Climate solver award - World Wide Fund for Nature (WWF){' '}
          </li>
          <li style={indexStyle.para}>
            {' '}
            2016: Winner of Urban Labs Innovation Challenge - University of Chicago{' '}
          </li>
        </ol>
      </div>
    </div>
  )
}

export default AboutUs
