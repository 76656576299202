import Header from '../../components/Header'
import Select from '@mui/material/Select'
import React, { useState } from 'react'
import { Box } from '@mui/material'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import OutlinedInput from '@mui/material/OutlinedInput'
import DeviceForm from '../device-form'
import DgForm from '../dg-form'
import MainsForm from '../mains-form'
import { DEVICE, DG, MAINS } from '../../constants'

const Onboard = () => {
  const [item, setItem] = useState('')
  const handleSelect = (e) => {
    setItem(e.target.value)
  }

  const renderForm = () => {
    switch (item) {
      case DEVICE:
        return <DeviceForm />
      case DG:
        return <DgForm />
      case MAINS:
        return <MainsForm />
      default:
        return null
    }
  }

  return (
    <Box m='50px'>
      <Header title='Onboarding Form' subtitle='Please select an entity to onboard' />

      <FormControl variant='outlined' sx={{ width: '50%' }}>
        <InputLabel id='select-label'>Select an option</InputLabel>
        <Select
          labelId='select-label'
          id='select'
          value={item}
          onChange={handleSelect}
          input={<OutlinedInput label='Select an option' />}
        >
          <MenuItem value={DEVICE}>Device</MenuItem>
          <MenuItem value={DG}>DG</MenuItem>
          <MenuItem value={MAINS}>Mains</MenuItem>
        </Select>
      </FormControl>

      <div>{renderForm()}</div>
    </Box>
  )
}

export default Onboard
