import { Box, Typography, useTheme, useMediaQuery } from '@mui/material'
import axios from 'axios'
import { useSessionContext } from 'supertokens-auth-react/recipe/session'
import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Grid from '@mui/material/Unstable_Grid2'
import { tokens } from '../../theme'
import Header from '../../components/Header'
import StatBox from '../../components/StatBox'
import { METRICS_API_INTERVAL, PRECISION_DIGITS } from '../../constants'
import MultiLineChart from '../../components/MultiLineChart'
import {
  GET_DG_OF_USER_API, // List Dg names, deviceId, and last service date.
  USER_API, // vendor name.
  GET_GRAPHS_OF_DG, // Graph for a single Dg.
  GET_DG_METRICS, // Single Dg metrics
  GET_DG_HISTORY,
} from '../../environment'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
} from 'chart.js'
import 'chartjs-adapter-moment'
import ElectricMeterIcon from '@mui/icons-material/ElectricMeter'
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt'
import SpeedIcon from '@mui/icons-material/Speed'
import DeviceThermostatIcon from '@mui/icons-material/DeviceThermostat'
import OilBarrelIcon from '@mui/icons-material/OilBarrel'
import ElectricalServicesIcon from '@mui/icons-material/ElectricalServices'
import Speedometer from '../../components/Speedometer'
import LiquidGauge from '../../components/LiquidGauge'
import SyncIcon from '@mui/icons-material/Sync'
import LoadProfile from '../load-profile'
import showRunningHistory from '../../utils'
import { UnauthorizedPage } from '../../errors'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  TimeScale,
  Title,
  Tooltip,
  Legend,
)

const DgPage = () => {
  const theme = useTheme()
  const smScreen = useMediaQuery(theme.breakpoints.up('sm'))
  const colors = tokens(theme.palette.mode)
  const params = useParams()
  const [deviceId, setDeviceId] = useState(params.deviceId)
  const [dgs, setDgs] = useState([])
  const [currDG, setCurrDG] = useState({})
  const [vendor, setVendor] = useState({})
  const [historyData, setHistoryData] = useState({})
  const [metricsData, setMetricsData] = useState({})
  const [dgGraphData, setDgGraphData] = useState({})
  const [authorized, setAuthorized] = useState(true)
  const navigate = useNavigate()

  let session = useSessionContext()
  let id = ''
  // console.log(deviceId);
  if (!session.loading) {
    let { userId } = session
    id = userId
  }

  const mains = id === deviceId

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userApiResponse = await axios.get(USER_API + '/' + id)
        setVendor(userApiResponse.value.data.data)
      } catch (error) {
        // console.error('Error fetching data:', error);
      }
    }
    fetchData()
  }, [id])

  useEffect(() => {
    const fetchData = async () => {
      if (!mains) {
        try {
          const response1 = await axios.get(GET_DG_OF_USER_API + id)
          setDgs(response1.data.data || [])
          if ((!deviceId || deviceId === '1') && response1.data.data != null) {
            setDeviceId(response1.data.data[0].device_id)
          } else if (response1.data.data == null) {
            navigate('/main/1')
          }
        } catch (error) {
          // alert(process.env.REACT_APP_ERROR_MSG);
        }
      }
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    function getCurrentDg() {
      setCurrDG(dgs.find((dg) => dg.device_id === deviceId))
    }
    getCurrentDg()
  }, [deviceId, dgs])

  useEffect(
    () => {
      const fetchData = () => {
        if (!mains && deviceId) {
          axios
            .get(GET_DG_METRICS + deviceId)
            .then((response) => {
              setMetricsData(response.data.data)
            })
            .catch((error) => {
              if (error.response?.status) {
                switch (error.response.status) {
                  case 403:
                    setAuthorized(false)
                    break
                }
              }
            })
        }
      }

      fetchData()

      const interval = setInterval(() => {
        fetchData()
      }, parseInt(METRICS_API_INTERVAL))

      return () => clearInterval(interval)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [deviceId],
  )

  useEffect(
    () => {
      const fetchData = async () => {
        try {
          if (deviceId) {
            const [graphsApiResponse, historyApiResponse] = await Promise.allSettled([
              axios.get(GET_GRAPHS_OF_DG + deviceId),
              axios.get(GET_DG_HISTORY + deviceId),
            ])

            if (graphsApiResponse.status === 'fulfilled') {
              setDgGraphData(graphsApiResponse.value.data.data)
            }
            if (historyApiResponse.status === 'fulfilled') {
              setHistoryData(historyApiResponse.value.data.data)
            }
          }
        } catch (error) {
          // console.error('Error fetching data:', error);
          if (error.response?.status) {
            switch (error.response.status) {
              case 403:
                setAuthorized(false)
                break
            }
          }
        }
      }
      fetchData()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [deviceId],
  )

  function metrics(data) {
    return (
      <React.Fragment key={vendor.name}>
        <Grid xs={12} sm={12} md={6} lg={3} xl={3}>
          <Box
            width='100%'
            backgroundColor={colors.primary[400]}
            display='flex'
            alignItems='center'
            justifyContent='center'
            borderRadius='16px'
          >
            <StatBox
              title={
                data.metrics
                  ? parseFloat((data.metrics.freq || 0) * 30).toFixed(PRECISION_DIGITS)
                  : '---'
              }
              subtitle='RPM'
              icon={<SpeedIcon sx={{ color: colors.greenAccent[600], fontSize: '26px' }} />}
            />
          </Box>
        </Grid>
        <Grid xs={12} sm={12} md={6} lg={3} xl={3}>
          <Box
            width='100%'
            backgroundColor={colors.primary[400]}
            display='flex'
            alignItems='center'
            justifyContent='center'
            borderRadius='16px'
          >
            <StatBox
              title={
                data.metrics
                  ? data.metrics.RPM !== 0
                    ? parseFloat(data.metrics.OilPressure).toFixed(PRECISION_DIGITS) + ' Bar'
                    : '---'
                  : '---'
              }
              subtitle='Lubricant Oil Pressure'
              progress='0.50'
              icon={<OilBarrelIcon sx={{ color: colors.greenAccent[600], fontSize: '26px' }} />}
            />
          </Box>
        </Grid>
        <Grid xs={12} sm={12} md={6} lg={3} xl={3}>
          <Box
            width='100%'
            backgroundColor={colors.primary[400]}
            display='flex'
            alignItems='center'
            justifyContent='center'
            borderRadius='16px'
          >
            <StatBox
              title={
                data.metrics
                  ? data.metrics.RPM !== 0
                    ? parseFloat(data.metrics.Temperature).toFixed(PRECISION_DIGITS) + ' °C'
                    : '---'
                  : '---'
              }
              subtitle='Water Temperature Signal'
              progress='0.50'
              icon={
                <DeviceThermostatIcon sx={{ color: colors.greenAccent[600], fontSize: '26px' }} />
              }
            />
          </Box>
        </Grid>
        <Grid xs={12} sm={12} md={6} lg={3} xl={3}>
          <Box
            width='100%'
            backgroundColor={colors.primary[400]}
            display='flex'
            alignItems='center'
            justifyContent='center'
            borderRadius='16px'
          >
            <StatBox
              title={
                data.metrics
                  ? parseFloat(data.metrics.freq || '0').toFixed(PRECISION_DIGITS) + ' Hz'
                  : '---'
              }
              subtitle='Frequency'
              progress='0.30'
              icon={<SyncIcon sx={{ color: colors.greenAccent[600], fontSize: '26px' }} />}
            />
          </Box>
        </Grid>
        <Grid xs={12} sm={12} md={6} lg={3} xl={3}>
          <Box
            width='100%'
            backgroundColor={colors.primary[400]}
            display='flex'
            alignItems='center'
            justifyContent='center'
            borderRadius='16px'
          >
            <StatBox
              title={
                data.metrics
                  ? parseFloat(data.metrics.VLL || '0').toFixed(PRECISION_DIGITS) + ' V'
                  : '---'
              }
              subtitle='Line to Line Voltage (Avg)'
              progress='0.75'
              icon={<ElectricBoltIcon sx={{ color: colors.greenAccent[600], fontSize: '26px' }} />}
            />
          </Box>
        </Grid>
        <Grid xs={12} sm={12} md={6} lg={3} xl={3}>
          <Box
            width='100%'
            backgroundColor={colors.primary[400]}
            display='flex'
            alignItems='center'
            justifyContent='center'
            borderRadius='16px'
          >
            <StatBox
              title={
                data.metrics
                  ? parseFloat(data.metrics.VLN || '0').toFixed(PRECISION_DIGITS) + ' V'
                  : '---'
              }
              subtitle='Line to Neutral Voltage (Avg)'
              progress='0.75'
              icon={<ElectricBoltIcon sx={{ color: colors.greenAccent[600], fontSize: '26px' }} />}
            />
          </Box>
        </Grid>
        <Grid xs={12} sm={12} md={6} lg={3} xl={3}>
          <Box
            width='100%'
            backgroundColor={colors.primary[400]}
            display='flex'
            alignItems='center'
            justifyContent='center'
            borderRadius='16px'
          >
            <StatBox
              title={
                data.metrics
                  ? parseFloat(data.metrics.amp || '0').toFixed(PRECISION_DIGITS) + ' A'
                  : '---'
              }
              subtitle='Current (Avg)'
              progress='0.50'
              icon={<ElectricMeterIcon sx={{ color: colors.greenAccent[600], fontSize: '26px' }} />}
            />
          </Box>
        </Grid>
        <Grid xs={12} sm={12} md={6} lg={3} xl={3}>
          <Box
            width='100%'
            backgroundColor={colors.primary[400]}
            display='flex'
            alignItems='center'
            justifyContent='center'
            borderRadius='16px'
          >
            <StatBox
              title={
                data.metrics ? parseFloat(data.metrics.PF || '0').toFixed(PRECISION_DIGITS) : '---'
              }
              subtitle='Power Factor'
              progress='0.30'
              icon={
                <ElectricalServicesIcon sx={{ color: colors.greenAccent[600], fontSize: '26px' }} />
              }
            />
          </Box>
        </Grid>
      </React.Fragment>
    )
  }

  function fuelLevelInLitres(data, dgsData) {
    const fuelInfo = { fuelLevel: 0, fuelCapacity: 0 }
    const dgData = dgsData ? dgsData.find((element) => element.device_id === deviceId) : null
    if (
      data.metrics &&
      Object.entries(data.metrics).length > 0 &&
      dgData &&
      Object.entries(dgData).length > 0
    ) {
      fuelInfo.fuelLevel = Math.max(
        (
          ((parseFloat(data.metrics.fuel_height) / 100) *
            dgData.fuel_tank_cross_section *
            dgData.fuel_tank_height) /
          1000000
        ).toFixed(PRECISION_DIGITS),
        0,
      )
      fuelInfo.fuelCapacity = (
        (dgData.fuel_tank_cross_section * dgData.fuel_tank_height) /
        1000000
      ).toFixed(PRECISION_DIGITS)
    }
    return fuelInfo
  }

  if (!authorized) {
    return <UnauthorizedPage />
  }

  return (
    <Box>
      <Box m='20px'>
        {/* HEADER */}
        <Box
          display={smScreen ? 'flex' : 'block'}
          flexDirection={smScreen ? 'row' : 'column'}
          justifyContent={smScreen ? 'space-between' : 'start'}
          alignItems={smScreen ? 'center' : 'start'}
          m='10px 0'
          marginBottom='2%'
        >
          <Header title={currDG ? currDG.name : mains ? 'Mains' : ''} />
          {/* {!mains ? (
        <Box
          backgroundColor={colors.redAccent[700]}
          borderRadius="16px"
          padding="1%"
          marginBottom="20px"
          marginLeft="32%"
        >
          <Typography variant="h4" fontWeight="bold" align="center">
            ₹{" "}
            {metricsData
              ? metricsData.fuel_efficiency
                ? metricsData.fuel_efficiency.unit_rate.toFixed(2)
                : "---"
              : "Add base Fuel Efficiencies"}
          </Typography>
          <Typography variant="h5" align="center">
            Per unit cost of electricity
          </Typography>
        </Box>
      ) : (
        ""
      )} */}
        </Box>
        {!mains ? (
          <Box
            display='flex'
            flexDirection={smScreen ? 'row' : 'column'}
            justifyContent='space-between'
            columnGap='2%'
          >
            <Box
              width='100%'
              backgroundColor={colors.primary[400]}
              display='flex'
              alignItems='center'
              justifyContent='center'
              marginBottom='20px'
              paddingTop='2%'
              borderRadius='16px'
            >
              <Box paddingTop='10px' marginRight='1px'>
                <Speedometer
                  data={historyData && Object.entries(historyData).length > 0 ? historyData : []}
                ></Speedometer>
              </Box>
            </Box>
            <Box
              width='100%'
              backgroundColor={colors.primary[400]}
              display='flex'
              alignItems='center'
              justifyContent='center'
              marginBottom='20px'
              borderRadius='16px'
              flexDirection={smScreen ? 'row' : 'column'}
            >
              <Box marginRight={smScreen ? '0%' : '20%'} padding='2%'>
                <LiquidGauge
                  fuel_height_per={
                    metricsData.metrics && Object.entries(metricsData.metrics).length > 0
                      ? Math.max(Number(metricsData.metrics.fuel_height), 0)
                      : 0
                  }
                ></LiquidGauge>
              </Box>
              {smScreen ? (
                <Box sx={{ marginLeft: '10%' }}>
                  <Typography variant='h4' marginBottom='10%' color={colors.grey[100]}>
                    Current fuel level: <b>{fuelLevelInLitres(metricsData, dgs).fuelLevel} L</b>
                  </Typography>
                  <Typography variant='h4' color={colors.grey[100]}>
                    Fuel tank capacity: <b>{fuelLevelInLitres(metricsData, dgs).fuelCapacity} L</b>
                  </Typography>
                </Box>
              ) : (
                <Box padding='2%'>
                  <Typography variant='h6' marginBottom='10%' color={colors.grey[100]}>
                    Current fuel level: <b>{fuelLevelInLitres(metricsData, dgs).fuelLevel} L</b>
                  </Typography>
                  <Typography variant='h6' color={colors.grey[100]}>
                    Fuel tank capacity: <b>{fuelLevelInLitres(metricsData, dgs).fuelCapacity} L</b>
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
        ) : (
          ''
        )}

        {/* METRICS */}
        <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          {metricsData && metricsData.metrics ? metrics(metricsData) : ''}
        </Grid>

        {/* <Grid xs={12}> */}
        <Grid>
          {!mains && dgGraphData && Object.entries(dgGraphData).length > 0 ? (
            <Box backgroundColor={colors.primary[400]} borderRadius='16px'>
              <Box
                mt='25px'
                display='flex'
                justifyContent='center'
                alignItems='center'
                paddingTop='1%'
              >
                <Box marginTop={smScreen ? '0%' : '2%'} marginBottom={smScreen ? '0%' : '2%'}>
                  <Typography variant='h3' fontWeight='600' color={colors.grey[100]}>
                    Fuel Level History
                  </Typography>
                </Box>
              </Box>
              <Box height='500px' m='-20px 0 0 0' marginTop='1%' padding='1%'>
                <MultiLineChart data={dgGraphData}></MultiLineChart>
              </Box>
            </Box>
          ) : (
            ''
          )}

          <Box backgroundColor={colors.primary[400]} borderRadius='16px'>
            <Box mt='25px' p='0 30px' display='flex' justifyContent='center' alignItems='center'>
              <Box marginTop='2%' marginBottom={smScreen ? '0%' : '1%'}>
                <Typography variant='h3' fontWeight='600' color={colors.grey[100]}>
                  Load Profile
                </Typography>
              </Box>
            </Box>
            <Box m='-5px 0 0 0' padding='1%'>
              {deviceId !== '1' ? <LoadProfile deviceId={deviceId} /> : ''}
            </Box>
          </Box>
        </Grid>
        {showRunningHistory(historyData, currDG ? currDG.name : 'dg-running-history')}
      </Box>
    </Box>
  )
}

export default DgPage
