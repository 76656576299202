;<style>@import url('https://fonts.googleapis.com/css2?family=Lato:ital@1&display=swap');</style>

const calculateStyles = (smScreen) => {
  return {
    container: {
      padding: smScreen ? '10px 150px 50px 150px' : '10px 32px 50px 32px',
      letterSpacing: smScreen ? '1.1px' : '1.5px',
    },
    header: {
      width: '100%',
      textAlign: 'center',
      paddingBottom: '25px',
    },
    mainheading: {
      fontSize: '30px',
      fontWeight: '600',
    },
    para: {
      fontSize: smScreen ? '18px' : '20px',
      lineHeight: '25px',
      fontFamily: 'Lato',
      marginTop: '0',
      marginBottom: '15px',
    },
    subheading: {
      fontSize: '25px',
      marginBottom: '5px',
    },
    subheading1: {
      fontFamily: 'Lato',
      fontSize: '25px',
      padding: '50px',
    },
  }
}

export default calculateStyles
