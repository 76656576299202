import { Line } from 'react-chartjs-2'

const MultiLineChart = ({ data }) => {
  return <Line options={options} data={chartDatas(data, data.time || [])} />
}

function chartDatas(data, labels) {
  const chartData = {
    labels,
    datasets: [
      {
        // label: "Fuel level (in L)",
        data: data.fuel || [],
        borderWidth: 2,
        borderColor: 'rgb(202, 111, 30)',
        backgroundColor: 'rgba(202, 111, 30, 0.5)',
      },
    ],
  }
  if (data.fuel) {
    chartData.datasets[0].data = data.fuel.map((value) => {
      if (value === 0) {
        return null // Replace zero with null to break the line
      }
      return value
    })
  }

  return chartData
}

const options = {
  responsive: true,
  maintainAspectRatio: false,
  layout: {
    padding: {
      left: 10,
      top: 10,
    },
  },
  plugins: {
    legend: {
      display: false,
      position: 'right',
      labels: {
        color: 'white',
      },
    },
  },
  scales: {
    x: {
      type: 'time',
      time: {
        unit: 'day',
        displayFormats: {
          day: 'MMM D',
        },
      },
      ticks: {
        display: true,
        color: 'white',
      },
    },
    y: {
      title: {
        display: true,
        text: 'Fuel (in L)',
        color: 'white',
      },
      ticks: {
        color: 'white',
      },
    },
  },
  elements: {
    point: {
      radius: 0,
    },
  },
}

export default MultiLineChart
