import axios from 'axios'
import { useEffect, useState } from 'react'
import {
  COMPLETED,
  FAILED,
  IN_PROGRESS,
  PAYMENT_STATUS_API_CALL_LIMIT,
  PAYMENT_STATUS_API_INTERVAL,
  PENDING,
} from '../../constants'
import { CREATE_INVOICE_API, GET_PAYMENT_STATUS } from '../../environment'
import { useParams } from 'react-router'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Topbar from '../global/Topbar'
import { Box, Button, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useNavigate } from 'react-router-dom'

const PaymentStatus = () => {
  const theme = useTheme()
  const smScreen = useMediaQuery(theme.breakpoints.up('sm'))
  const navigate = useNavigate()
  const params = useParams()
  const orderId = params.orderId
  const [paymentStatus, setPaymentStatus] = useState(PENDING)
  const [callCount, setCallCount] = useState(0)
  const [shouldFetchData, setShouldFetchData] = useState(true)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(GET_PAYMENT_STATUS + orderId)
        setPaymentStatus(response.data.data.status.toUpperCase())

        if (
          (paymentStatus !== PENDING && paymentStatus !== IN_PROGRESS) ||
          callCount >= PAYMENT_STATUS_API_CALL_LIMIT
        ) {
          clearInterval(fetchDataInterval)
          setShouldFetchData(false)
          if (paymentStatus === COMPLETED) {
            toast.success('Your payment was successful!')
            await axios.post(CREATE_INVOICE_API, {
              payment_id: orderId,
            })
            toast.success(
              'Invoice has been generated successfully and sent to your registered email!',
            )
          }
        }
      } catch (error) {
        toast.error(
          'We are encountering some issue in processing your invoice. Please check you mail after some time...',
        )
      }
    }

    const fetchDataInterval = setInterval(() => {
      if (shouldFetchData) {
        fetchData()
      }
      setCallCount((prevCallCount) => prevCallCount + 1)
    }, PAYMENT_STATUS_API_INTERVAL)

    return () => {
      clearInterval(fetchDataInterval)
    }
  }, [shouldFetchData, callCount])

  const callPaymentPage = () => {
    navigate('/payment')
  }

  return (
    <Box>
      <Topbar />
      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        minHeight='100vh'
        padding={smScreen ? '30px 0px 100px 0px' : '100px 0px 160px 0px'}
        boxSizing='border-box'
      >
        <Box
          display='flex'
          flexDirection='column'
          alignItems='center'
          width={smScreen ? '60%' : '80%'}
          border='2px solid white'
          padding={smScreen ? '40px' : '25px'}
          borderRadius='8px'
          boxShadow='0 4px 8px rgba(0, 0, 0, 0.1)'
          margin='30px 0px'
        >
          <p style={{ textAlign: 'center', fontSize: '20px', marginTop: '10px' }}>
            Status of your payment with ID <b> {orderId} </b> is: <br />{' '}
            <b style={{ color: displayTextColor(paymentStatus) }}> {paymentStatus} </b>
          </p>
          <p style={{ textAlign: 'center', fontSize: '20px' }}>
            Please wait for a few seconds for the status to update. Do not close this window or
            refresh the page.
            <br />
            In case of failure, please try to make the payment again.
          </p>
          <p style={{ textAlign: 'center', fontSize: '20px', marginTop: '10px' }}>
            Click below to purchase more units
          </p>
          <Button
            variant='contained'
            color='success'
            size='large'
            onClick={callPaymentPage}
            style={{ width: smScreen ? '20%' : '40%' }}
          >
            <Typography
              variant='h6'
              align='center'
              fontWeight='bold'
              sx={{ fontSize: smScreen ? '20px' : '17px' }}
            >
              Purchase
            </Typography>
          </Button>
          <ToastContainer
            position='top-right'
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme='colored'
          />
        </Box>
      </Box>
    </Box>
  )
}

function displayTextColor(status) {
  let textColor
  switch (status) {
    case COMPLETED:
      textColor = 'green'
      break
    case IN_PROGRESS:
      textColor = 'yellow'
      break
    case FAILED:
      textColor = 'red'
      break
    default:
      textColor = 'white'
  }
  return textColor
}

export default PaymentStatus
