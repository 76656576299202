import { Box, Button, TextField, Select, MenuItem } from '@mui/material'
import { Formik } from 'formik'
import * as yup from 'yup'
import useMediaQuery from '@mui/material/useMediaQuery'
import Header from '../../components/Header'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useSessionContext } from 'supertokens-auth-react/recipe/session'
import { useNavigate } from 'react-router-dom'
import { ERROR_MSG, USER_API } from '../../environment'
import { INDIA_PHONE_CODE } from '../../constants'

const UserForm = () => {
  const isNonMobile = useMediaQuery('(min-width:600px)')
  const navigate = useNavigate()

  let session = useSessionContext()
  let id = ''
  if (!session.loading) {
    let { userId } = session
    id = userId
    // console.log(id);
  }

  const handleFormSubmit = (values) => {
    values.user_id = id
    values.phone = INDIA_PHONE_CODE + values.phone

    axios
      .post(USER_API, values)
      .then((response) => {
        // console.log(response);
        alert('User onboarded/updated successfully!!!')
        navigate('/dg/1')
      })
      .catch((error) => {
        alert(ERROR_MSG)
      })
  }

  const states = [
    'andaman and nicobar islands',
    'andhra pradesh',
    'arunachal pradesh',
    'assam',
    'bihar',
    'chandigarh',
    'chhattisgarh',
    'dadra and nagar haveli and daman and diu',
    'delhi',
    'goa',
    'gujarat',
    'haryana',
    'himachal pradesh',
    'jharkhand',
    'karnataka',
    'kerala',
    'ladakh',
    'lakshadweep',
    'madhya pradesh',
    'maharashtra',
    'manipur',
    'meghalaya',
    'mizoram',
    'nagaland',
    'odisha',
    'puducherry',
    'punjab',
    'rajasthan',
    'sikkim',
    'tamil nadu',
    'telangana',
    'tripura',
    'uttar pradesh',
    'uttarakhand',
    'west bengal',
  ]

  const capitalizeFirstLetter = (string) => {
    return string
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
  }

  const lowerlizeFirstLetter = (string) => {
    return string
      .split(' ')
      .map((word) => word.charAt(0).toLowerCase() + word.slice(1))
      .join(' ')
  }

  const [initialValues, setInitialValues] = useState({
    name: '',
    gst_number: '',
    address_line_1: '',
    address_line_2: '',
    city: '',
    state: '',
    pin_code: '',
    phone: '',
    email: '',
    point_of_contact: '',
  })

  const fetchInitialValuesFromAPI = async () => {
    try {
      const endpoint = USER_API + '/' + id
      const response = await axios.get(endpoint)
      const { user_id, updated_at, created_at, ...data } = response.data.data
      data.state = lowerlizeFirstLetter(data.state)
      data.phone = data.phone.slice(2)
      setInitialValues(data)
    } catch (error) {
      // console.error('Error fetching data from API:', error)
    }
  }

  useEffect(() => {
    fetchInitialValuesFromAPI()
  }, [])

  return (
    <Box m='50px'>
      <Header title='User Onboarding Form' subtitle='Onboard a new user' />

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        enableReinitialize
        validationSchema={checkoutSchema}
        validate={validate}
      >
        {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display='grid'
              gap='30px'
              gridTemplateColumns='repeat(4, minmax(0, 1fr))'
              sx={{
                '& > div': { gridColumn: isNonMobile ? undefined : 'span 4' },
              }}
            >
              <TextField
                fullWidth
                variant='filled'
                type='text'
                label='Name'
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.name}
                name='name'
                error={!!touched.name && !!errors.name}
                helperText={touched.name && errors.name}
                sx={{ gridColumn: 'span 2' }}
              />
              <TextField
                fullWidth
                variant='filled'
                type='text'
                label='GST Number'
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.gst_number}
                name='gst_number'
                error={!!touched.gst_number && !!errors.gst_number}
                helperText={touched.gst_number && errors.gst_number}
                sx={{ gridColumn: 'span 2' }}
              />
              <TextField
                fullWidth
                variant='filled'
                type='text'
                label='Address Line 1'
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.address_line_1}
                name='address_line_1'
                error={!!touched.address_line_1 && !!errors.address_line_1}
                helperText={touched.address_line_1 && errors.address_line_1}
                sx={{ gridColumn: 'span 2' }}
              />
              <TextField
                fullWidth
                variant='filled'
                type='text'
                label='Address Line 2'
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.address_line_2}
                name='address_line_2'
                error={!!touched.address_line_2 && !!errors.address_line_2}
                helperText={touched.address_line_2 && errors.address_line_2}
                sx={{ gridColumn: 'span 2' }}
              />
              <TextField
                fullWidth
                variant='filled'
                type='text'
                label='City'
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.city}
                name='city'
                error={!!touched.city && !!errors.city}
                helperText={touched.city && errors.city}
                sx={{ gridColumn: 'span 2' }}
              />
              <Select
                fullWidth
                variant='filled'
                label='State'
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.state || ''}
                name='state'
                displayEmpty
                error={!!touched.state && !!errors.state}
                helperText={touched.state && errors.state}
                sx={{ gridColumn: 'span 2' }}
              >
                <MenuItem value='' disabled>
                  Select a State
                </MenuItem>
                {states.map((state) => (
                  <MenuItem key={state} value={state}>
                    {capitalizeFirstLetter(state)}
                  </MenuItem>
                ))}
              </Select>
              <TextField
                fullWidth
                variant='filled'
                type='text'
                label='Pin Code'
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.pin_code}
                name='pin_code'
                error={!!touched.pin_code && !!errors.pin_code}
                helperText={touched.pin_code && errors.pin_code}
                sx={{ gridColumn: 'span 2' }}
              />
              <TextField
                fullWidth
                variant='filled'
                type='text'
                label='Phone'
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.phone}
                name='phone'
                error={!!touched.phone && !!errors.phone}
                helperText={touched.phone && errors.phone}
                sx={{ gridColumn: 'span 2' }}
              />
              <TextField
                fullWidth
                variant='filled'
                type='text'
                label='Email'
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.email}
                name='email'
                error={!!touched.email && !!errors.email}
                helperText={touched.email && errors.email}
                sx={{ gridColumn: 'span 2' }}
              />
              <TextField
                fullWidth
                variant='filled'
                type='text'
                label='Point of Contact'
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.point_of_contact}
                name='point_of_contact'
                error={!!touched.point_of_contact && !!errors.point_of_contact}
                helperText={touched.point_of_contact && errors.point_of_contact}
                sx={{ gridColumn: 'span 2' }}
              />
            </Box>
            <Box display='flex' justifyContent='center' mt='80px'>
              <Button type='submit' color='secondary' variant='contained' size='large'>
                SUBMIT
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  )
}

const checkoutSchema = yup.object().shape({
  name: yup.string().required('required'),
  gst_number: yup.string().required('required'),
  address_line_1: yup.string(),
  address_line_2: yup.string(),
  city: yup.string().required(),
  state: yup.string(),
  pin_code: yup.string(),
  phone: yup.number().required('required'),
  email: yup.string().required('required'),
  point_of_contact: yup.string().required('required'),
})

const validate = (values, props) => {
  const errors = {}
  const phoneExp = /^[6-9][0-9]{9}$/
  const emailExp = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
  const pincodeExp = /^[1-9][0-9]{5}$/
  if (!phoneExp.test(values.phone)) {
    errors.phone = 'Not a valid number'
  }
  if (values.email && !emailExp.test(values.email)) {
    errors.email = 'Not a valid email'
  }
  if (!pincodeExp.test(values.pin_code)) {
    errors.pin_code = 'Not a valid pincode'
  }
  return errors
}

export default UserForm
