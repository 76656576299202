import React from 'react'
import { Box, IconButton, Link } from '@mui/material'
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined'
import { useProSidebar } from 'react-pro-sidebar'
import LogoutIcon from '@mui/icons-material/Logout'
import Tooltip from '@mui/material/Tooltip'
import OnLogout from '../logout'

const Topbar = () => {
  const { toggleSidebar, broken, rtl } = useProSidebar()

  return (
    <Box display='flex' justifyContent='space-between' p={2}>
      <Box display='flex'>
        {broken && !rtl && (
          <IconButton sx={{ margin: '0 6 0 2' }} onClick={() => toggleSidebar()}>
            <MenuOutlinedIcon />
          </IconButton>
        )}
      </Box>
      <Box display='flex'>
        <Link
          href='/dg/1'
          color='inherit'
          style={{
            marginTop: '1%',
            fontSize: '18px',
            paddingRight: '15px',
            textDecoration: 'none',
          }}
        >
          Home
        </Link>
        <Link
          href='/about-us'
          color='inherit'
          style={{
            marginTop: '1%',
            fontSize: '18px',
            paddingRight: '15px',
            textDecoration: 'none',
          }}
        >
          About Us
        </Link>
        <Link
          href='/privacy-policy'
          color='inherit'
          style={{
            marginTop: '1%',
            fontSize: '18px',
            paddingRight: '5px',
            textDecoration: 'none',
          }}
        >
          Privacy Policy
        </Link>
        <Link
          href='/refund-policy'
          color='inherit'
          style={{
            marginTop: '1%',
            fontSize: '18px',
            paddingRight: '5px',
            textDecoration: 'none',
            paddingLeft: '5px',
          }}
        >
          Refund Policy
        </Link>
        <Tooltip title='Logout'>
          <IconButton onClick={OnLogout}>
            <LogoutIcon />
          </IconButton>
        </Tooltip>
        {broken && rtl && (
          <IconButton sx={{ margin: '0 6 0 2' }} onClick={() => toggleSidebar()}>
            <MenuOutlinedIcon />
          </IconButton>
        )}
      </Box>
    </Box>
  )
}

export default Topbar
