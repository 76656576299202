export const METRICS_API_INTERVAL = 60000
export const PRECISION_DIGITS = 2
export const INDIA_PHONE_CODE = '91'
export const GST_PERCENTAGE = 18
export const EXTRA_CHARGES = 0

// Payment statuses
export const DRAFT = 'DRAFT'
export const IN_PROGRESS = 'IN-PROGRESS'
export const PENDING = 'PENDING'
export const COMPLETED = 'COMPLETED'
export const FAILED = 'FAILED'

export const DEVICE = 'device'
export const DG = 'dg'
export const MAINS = 'main'
export const SUCCESS_CODE = 200

export const PAYMENT_STATUS_API_INTERVAL = 3000
export const PAYMENT_STATUS_API_CALL_LIMIT = 40
