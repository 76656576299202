import { Routes, Route } from 'react-router-dom'
import { CssBaseline, ThemeProvider } from '@mui/material'
import { ColorModeContext, useMode } from './theme'
import SuperTokens, { getSuperTokensRoutesForReactRouterDom } from 'supertokens-auth-react'
import EmailPassword from 'supertokens-auth-react/recipe/emailpassword'
import * as reactRouterDom from 'react-router-dom'
import Session from 'supertokens-auth-react/recipe/session'
import { SessionAuth } from 'supertokens-auth-react/recipe/session'
import UserForm from './scenes/user-form'
import { MyProSidebarProvider } from './scenes/global/sidebar/sidebarContext'
import Topbar from './scenes/global/Topbar'

import {
  API_DOMAIN,
  SUPERTOKENS_API_BASE_PATH,
  SUPERTOKENS_APP_NAME,
  SUPERTOKENS_WEBSITE_BASE_PATH,
  WEBSITE_DOMAIN,
} from './environment'
import DgPage from './scenes/dg'
import OptInForm from './scenes/opt-in'
import AboutUs from './scenes/about-us'
import PrivacyPolicy from './scenes/privacy-policy'
import RefundPolicy from './scenes/refund-policy'
import PaymentStatus from './scenes/payment-status'
import Onboard from './scenes/onboard'
import MainsPage from './scenes/mains'
import Payment from './scenes/payment'
import PaymentHistory from './scenes/payment-history'
import { PageNotFound } from './errors'

SuperTokens.init({
  appInfo: {
    // learn more about this on https://supertokens.com/docs/emailpassword/appinfo
    appName: SUPERTOKENS_APP_NAME,
    apiDomain: API_DOMAIN,
    websiteDomain: WEBSITE_DOMAIN,
    apiBasePath: SUPERTOKENS_API_BASE_PATH,
    websiteBasePath: SUPERTOKENS_WEBSITE_BASE_PATH,
  },
  recipeList: [
    EmailPassword.init({
      getRedirectionURL: async (context) => {
        if (context.action === 'SUCCESS') {
          if (context.isNewUser) {
            return '/user-form'
          }
          if (context.redirectToPath !== undefined) {
            // we are navigating back to where the user was before they authenticated
            return context.redirectToPath
          }
          return '/dg/1'
        }
        return undefined
      },
    }),
    Session.init(),
  ],
})

function App() {
  const [theme, colorMode] = useMode()

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Routes>
          {getSuperTokensRoutesForReactRouterDom(reactRouterDom)}
          <Route
            path='/user-form'
            element={
              <SessionAuth>
                <UserForm />
              </SessionAuth>
            }
          />
          <Route
            path='/dg/:deviceId'
            element={
              <SessionAuth>
                <MyProSidebarProvider>
                  <div style={{ height: '100%', width: '100%' }}>
                    <main>
                      <Topbar />
                      <DgPage />
                    </main>
                  </div>
                </MyProSidebarProvider>
              </SessionAuth>
            }
          />
          <Route
            path='/main/:deviceId'
            element={
              <SessionAuth>
                <MyProSidebarProvider>
                  <div style={{ height: '100%', width: '100%' }}>
                    <main>
                      <Topbar />
                      <MainsPage />
                    </main>
                  </div>
                </MyProSidebarProvider>
              </SessionAuth>
            }
          />
          <Route
            path='/dg/1'
            element={
              <SessionAuth>
                <MyProSidebarProvider>
                  <div style={{ height: '100%', width: '100%' }}>
                    <main>
                      <Topbar />
                      <DgPage />
                    </main>
                  </div>
                </MyProSidebarProvider>
              </SessionAuth>
            }
          />
          <Route
            path='/about-us'
            element={
              <SessionAuth>
                <div style={{ height: '100%', width: '100%' }}>
                  <main>
                    <Topbar />
                    <AboutUs />
                  </main>
                </div>
              </SessionAuth>
            }
          />
          <Route
            path='/privacy-policy'
            element={
              <SessionAuth>
                <div style={{ height: '100%', width: '100%' }}>
                  <main>
                    <Topbar />
                    <PrivacyPolicy />
                  </main>
                </div>
              </SessionAuth>
            }
          />
          <Route
            path='/refund-policy'
            element={
              <SessionAuth>
                <div style={{ height: '100%', width: '100%' }}>
                  <main>
                    <Topbar />
                    <RefundPolicy />
                  </main>
                </div>
              </SessionAuth>
            }
          />
          <Route
            path='/payment/status/:orderId'
            element={
              <SessionAuth>
                <div style={{ height: '100%', width: '100%' }}>
                  <main>
                    <PaymentStatus />
                  </main>
                </div>
              </SessionAuth>
            }
          />
          <Route
            path='/onboard'
            element={
              <SessionAuth>
                <div style={{ height: '100%', width: '100%' }}>
                  <Onboard />
                </div>
              </SessionAuth>
            }
          />
          <Route
            path='/payment'
            element={
              <SessionAuth>
                <div style={{ height: '100%', width: '100%' }}>
                  <Payment />
                </div>
              </SessionAuth>
            }
          />
          <Route
            path='/payment/history/:id'
            element={
              <SessionAuth>
                <div style={{ height: '100%', width: '100%' }}>
                  <PaymentHistory />
                </div>
              </SessionAuth>
            }
          />
          <Route path='/whatsapp/opt-in' element={<OptInForm />} />
          <Route
            path='*'
            element={
              <SessionAuth>
                <div style={{ height: '100%', width: '100%' }}>
                  <main>
                    <Topbar />
                    <PageNotFound />
                  </main>
                </div>
              </SessionAuth>
            }
          />
        </Routes>
      </ThemeProvider>
    </ColorModeContext.Provider>
  )
}

export default App
