import { useMediaQuery, useTheme } from '@mui/material'
import calculateStyles from '../refund-policy/style'

const PrivacyPolicy = () => {
  const theme = useTheme()
  const smScreen = useMediaQuery(theme.breakpoints.up('sm'))
  const indexStyle = calculateStyles(smScreen)

  return (
    <div style={indexStyle.container}>
      <div style={indexStyle.header}>
        <h1 style={indexStyle.mainheading}>PRIVACY POLICY</h1>
      </div>
      <div>
        <div style={indexStyle.para}>
          This Privacy Policy governs the collection, use, and disclosure of personal information by
          Chakr Innovation Pvt. Ltd. We are deeply committed to safeguarding your privacy and
          ensuring the security of your personal data. This Privacy Policy elucidates how we
          collect, use, and handle your personal information when you engage with us, either online
          or offline. By accessing our websites, utilizing our products and services, or
          participating in any business interactions with us, you signify your agreement to the
          terms of this Privacy Policy.
        </div>
        <ol>
          <li style={indexStyle.para}>
            <b style={indexStyle.subheading}> Information We Collect </b>
          </li>
          <div style={indexStyle.para}>
            We collect two categories of information when you visit our website:
          </div>
          <ol type='a'>
            <li style={indexStyle.para}>
              Personal Information: This category includes data that can personally identify you,
              such as your name, email address, postal address, phone number, or any other
              information voluntarily provided during your interactions with our website.
            </li>

            <li style={indexStyle.para}>
              Non-Personal Information: This refers to data that does not personally identify you
              but is collected for statistical purposes or to enhance your browsing experience.
              Non-personal information encompasses your IP (Internet Protocol) address, browser
              type, operating system, device information, and browsing behavior on our website.
            </li>
          </ol>
          <br />
          <li style={indexStyle.para}>
            <b style={indexStyle.subheading}>How We Use Your Information</b>
          </li>
          <div style={indexStyle.para}>
            {' '}
            We employ the collected information for the following purposes:
          </div>

          <ol type='a'>
            <li style={indexStyle.para}>Personal Information:</li>
            <ol type='I'>
              <li style={indexStyle.para}>
                To address your inquiries, provide customer support, or fulfill your requests.
              </li>

              <li style={indexStyle.para}>
                To personalize your website experience and deliver pertinent content.
              </li>

              <li style={indexStyle.para}>
                To send you promotional emails, newsletters, or other communications concerning our
                services, contingent upon your consent.
              </li>

              <li style={indexStyle.para}>
                To process transactions, including purchases and financial transactions.
              </li>

              <li style={indexStyle.para}>
                To adhere to legal obligations and safeguard our rights, property, and safety.
              </li>
            </ol>

            <li style={indexStyle.para}>Non-Personal Information:</li>
            <ol type='I'>
              <li style={indexStyle.para}>
                To analyze trends, monitor user interactions, and compile demographic information
                for the enhancement of our website and services.
              </li>

              <li style={indexStyle.para}>
                To detect and prevent fraud, security breaches, or other illicit activities.
              </li>
            </ol>
          </ol>
          <br />
          <li style={indexStyle.para}>
            <b style={indexStyle.subheading}>Methods of Information Collection</b>
          </li>
          <ol type='a'>
            <li style={indexStyle.para}>
              Direct Collection: We collect information directly from you when you register,
              complete contact forms, comment on our websites, or contact us via phone or email.
            </li>

            <li style={indexStyle.para}>
              Passive Collection: We may utilize cookies and other tracking technologies to enhance
              your browsing experience, analyze website usage, and deliver customized content. You
              have the option to accept or decline cookies through your browser settings.
              Nonetheless, disabling cookies may affect specific website features and
              functionalities.
            </li>

            <li style={indexStyle.para}>
              Third-Party Sources: If you utilize social media features integrated into our
              websites, certain information about you may be provided to us by the respective
              third-party social media site, including your name and email address.
            </li>
          </ol>
          <br />
          <li style={indexStyle.para}>
            <b style={indexStyle.subheading}>Disclosure of Personal Information</b>
          </li>
          <div style={indexStyle.para}>
            We may disclose your personal information under the following circumstances:
          </div>
          <ol type='a'>
            <li style={indexStyle.para}>
              Service Providers, Contractors, and Processors: We may share your personal information
              with our service providers, contractors, and processors who assist us in various
              services, such as website management, IT security, communications, and related
              activities. We ensure that these entities maintain a similar level of protection for
              your data and prohibit them from using the information for unrelated purposes or
              selling it.
            </li>

            <li style={indexStyle.para}>
              Legal Disclosures: We may disclose personal information if required by applicable law
              or legal processes, including requests from public authorities for national security
              or law enforcement purposes.
            </li>

            <li style={indexStyle.para}>
              Advisors and Lawyers: We may share personal information with our legal counsel and
              professional advisors to obtain advice and protect our business interests.
            </li>

            <li style={indexStyle.para}>
              Change of Ownership: In the event of a merger, sale of company assets, financing, or
              acquisition of our business, personal information may be disclosed as part of the
              transaction.
            </li>

            <li style={indexStyle.para}>
              With Your Consent: We may disclose personal information with your consent or at your
              direction.
            </li>

            <li style={indexStyle.para}>
              Non-Personal Information: Aggregated or de-identified information that cannot
              reasonably identify you may be shared.
            </li>
          </ol>
          <br />
          <li style={indexStyle.para}>
            <b style={indexStyle.subheading}>Legal Basis for Processing Personal Information</b>
          </li>
          <div style={indexStyle.para}>
            The legal basis for processing your personal information depends on the purpose of
            collection. This may include your consent and the fulfilment of business.
          </div>
          <li style={indexStyle.para}>
            <b style={indexStyle.subheading}>Retention of Your Personal Data</b>
          </li>
          <div style={indexStyle.para}>
            Chakr Innovation Pvt. Ltd. will retain your personal data only for as long as necessary
            to fulfill the purposes outlined in this Privacy Policy. We will retain and use your
            personal data to the extent required to comply with our legal obligations, resolve
            disputes, and enforce our legal agreements and policies.
          </div>

          <li style={indexStyle.para}>
            <b style={indexStyle.subheading}>Security of Your Personal Data</b>
          </li>
          <div style={indexStyle.para}>
            The security of your personal data is of paramount importance to us. While we employ
            commercially acceptable means to protect your personal data, please note that no method
            of transmission over the Internet or electronic storage is entirely secure. We cannot
            guarantee absolute security.
          </div>
          <li style={indexStyle.para}>
            <b style={indexStyle.subheading}>Changes to this Privacy Policy</b>
          </li>
          <div style={indexStyle.para}>
            We may update our Privacy Policy periodically. We will notify you of any changes by
            posting the new Privacy Policy on this page. We recommend reviewing this Privacy Policy
            periodically for any updates, as changes become effective upon posting. Contact Us If
            you have any questions regarding this Privacy Policy, please feel free to contact us via
            email at info@chakr.in.
          </div>
        </ol>
      </div>
    </div>
  )
}

export default PrivacyPolicy
