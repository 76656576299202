export const API_DOMAIN = process.env.REACT_APP_API_DOMAIN
export const WEBSITE_DOMAIN = process.env.REACT_APP_WEBSITE_DOMAIN
export const USER_API = process.env.REACT_APP_USER_API
export const WHATSAPP_OPT_IN_API = process.env.REACT_APP_SEND_WHATSAPP_OPT_IN_API
export const USER_ALL_API = process.env.REACT_APP_USER_ALL_API
export const DEVICE_API = process.env.REACT_APP_DEVICE_API
export const DEVICE_UNREGISTERED = process.env.REACT_APP_DEVICE_UNREGISTERED_API
export const ONBOARD_DG_API = process.env.REACT_APP_ONBOARD_DG_API
export const GET_DG_OF_USER_API = process.env.REACT_APP_GET_DG_OF_USER_API
export const GET_MAINS_OF_USER_API = process.env.REACT_APP_GET_MAINS_OF_USER_API
export const GET_GRAPHS_OF_DG = process.env.REACT_APP_GET_GRAPHS_OF_DG
export const GET_DG_HISTORY = process.env.REACT_APP_GET_DG_HISTORY_API
export const GET_DG_METRICS = process.env.REACT_APP_DG_METRICS_API
export const GET_DG_LOAD_PROFILE = process.env.REACT_APP_GET_DG_LOAD_PROFILE_API
export const GET_MAINS_EXISTS = process.env.REACT_APP_GET_MAINS_EXISTS_API
export const GET_PAYMENT_STATUS = process.env.REACT_APP_GET_PAYMENT_STATUS_API
export const USER_CONFIG_API = process.env.REACT_APP_USER_CONFIG_API
export const PAYMENT_INITIATE_API = process.env.REACT_APP_PAYMENT_INITIATE_API
export const ONBOARD_MAINS_API = process.env.REACT_APP_ONBOARD_MAINS_API
export const CREATE_INVOICE_API = process.env.REACT_APP_CREATE_INVOICE_API
export const SUPERTOKENS_APP_NAME = process.env.REACT_APP_SUPERTOKENS_APP_NAME
export const SUPERTOKENS_API_BASE_PATH = process.env.REACT_APP_SUPERTOKENS_API_BASE_PATH
export const SUPERTOKENS_WEBSITE_BASE_PATH = process.env.REACT_APP_SUPERTOKENS_WEBSITE_BASE_PATH
export const ERROR_MSG = process.env.REACT_APP_ERROR_MSG
export const PILOT_USERS_ARRAY = process.env.REACT_APP_PILOT_USERS_ARRAY
export const GET_USER_PAYMENT_HISTORY_API = process.env.REACT_APP_GET_USER_PAYMENT_HISTORY_API
