import LiquidFillGauge from 'react-liquid-gauge'
import React from 'react'
import { color } from 'd3-color'
import { interpolateRgb } from 'd3-interpolate'
import { useTheme, useMediaQuery } from '@mui/material'

const LiquidGauge = ({ fuel_height_per }) => {
  const interpolate = interpolateRgb('#D22B2B', '#228B22')
  const fillColor = interpolate(fuel_height_per / 100)
  const theme = useTheme()
  const smScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const radius = smScreen ? 120 : 140

  const gradientStops = [
    {
      key: '0%',
      stopColor: color(fillColor).darker(0.5).toString(),
      stopOpacity: 1,
      offset: '0%',
    },
    {
      key: '15%',
      stopColor: fillColor,
      stopOpacity: 0.75,
      offset: '50%',
    },
    {
      key: '70%',
      stopColor: color(fillColor).darker(0.5).toString(),
      stopOpacity: 0.5,
      offset: '100%',
    },
  ]

  return (
    <LiquidFillGauge
      style={{ marginLeft: '15%' }}
      width={radius * 2}
      height={radius * 2}
      value={fuel_height_per}
      percent='%'
      textSize={1}
      textOffsetX={10}
      textOffsetY={30}
      textRenderer={(props) => {
        const value = Math.round(props.value)
        const radius = Math.min(props.height / 2, props.width / 2)
        const textPixels = (props.textSize * radius) / 2
        const valueStyle = {
          fontSize: textPixels,
        }
        const percentStyle = {
          fontSize: textPixels * 0.6,
        }

        return (
          <tspan>
            <tspan className='value' style={valueStyle}>
              {value}
            </tspan>
            <tspan style={percentStyle}>{props.percent}</tspan>
          </tspan>
        )
      }}
      riseAnimation
      waveAnimation
      waveFrequency={3}
      waveAmplitude={2}
      gradient
      gradientStops={gradientStops}
      circleStyle={{
        fill: fillColor,
      }}
      waveStyle={{
        fill: fillColor,
      }}
      textStyle={{
        fill: color('#A9A9A9').toString(),
        fontFamily: 'Arial',
      }}
      waveTextStyle={{
        fill: color('#fff').toString(),
        fontFamily: 'Arial',
      }}
    />
  )
}

export default LiquidGauge
