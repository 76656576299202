import { Box, Typography, useTheme, useMediaQuery } from '@mui/material'
import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { tokens } from '../../theme'
import LoadProfile from '../load-profile'
import { useSessionContext } from 'supertokens-auth-react/recipe/session'
import { GET_DG_HISTORY, GET_MAINS_OF_USER_API } from '../../environment'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
} from 'chart.js'
import 'chartjs-adapter-moment'
import showRunningHistory from '../../utils'
import { UnauthorizedPage } from '../../errors'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  TimeScale,
  Title,
  Tooltip,
  Legend,
)

const MainsPage = () => {
  const params = useParams()
  const theme = useTheme()
  const smScreen = useMediaQuery(theme.breakpoints.up('sm'))
  const colors = tokens(theme.palette.mode)
  const [deviceId, setDeviceId] = useState(params.deviceId)
  const [historyData, setHistoryData] = useState({})
  const [authorized, setAuthorized] = useState(true)
  const currMain = 'mains_running_history'

  let session = useSessionContext()
  let id = ''
  // console.log(deviceId);
  if (!session.loading) {
    let { userId } = session
    id = userId
  }

  useEffect(() => {
    if (deviceId === '1') {
      const fetchData = async () => {
        try {
          const resMains = await axios.get(GET_MAINS_OF_USER_API + id)
          if (resMains.data.data != null) {
            setDeviceId(resMains.data.data[0].device_id)
          }
        } catch (error) {
          // alert(process.env.REACT_APP_ERROR_MSG);
        }
      }
      fetchData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!deviceId || deviceId !== '1') {
      axios
        .get(GET_DG_HISTORY + deviceId)
        .then((response) => {
          setHistoryData(response.data.data)
        })
        .catch((error) => {
          //  alert(process.env.REACT_APP_ERROR_MSG);
          if (error.response?.status) {
            switch (error.response.status) {
              case 403:
                setAuthorized(false)
                break
            }
          }
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceId])

  if (!authorized) {
    return <UnauthorizedPage />
  }

  return (
    <>
      <Box backgroundColor={colors.primary[400]} borderRadius='16px' m='20px'>
        <Box mt='25px' p='0 30px' display='flex' justifyContent='center' alignItems='center'>
          <Box marginTop='2%' marginBottom={smScreen ? '0%' : '1%'}>
            <Typography variant='h3' fontWeight='600' color={colors.grey[100]}>
              Load Profile
            </Typography>
          </Box>
        </Box>
        <Box m='-5px 0 0 0' padding='1%'>
          {deviceId !== '1' ? <LoadProfile deviceId={deviceId} /> : ''}
        </Box>
      </Box>

      <Box m='20px'>{showRunningHistory(historyData, currMain)}</Box>
    </>
  )
}

export default MainsPage
