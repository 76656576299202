import React, { useState, useEffect } from 'react'
import { Box, Typography, Button, TextField, useMediaQuery } from '@mui/material'
import axios from 'axios'
import { useSessionContext } from 'supertokens-auth-react/recipe/session'
import { PAYMENT_INITIATE_API, USER_CONFIG_API, PILOT_USERS_ARRAY } from '../../environment'
import { EXTRA_CHARGES, GST_PERCENTAGE } from '../../constants'
import { useTheme } from '@emotion/react'
import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Topbar from '../global/Topbar'
import logo from '../../logo.png'

const Payment = () => {
  const theme = useTheme()
  const smScreen = useMediaQuery(theme.breakpoints.up('sm'))
  const [perUnitCost, setPerUnitCost] = useState(0)
  const [purchasedUnits, setPurchasedUnits] = useState(0)
  const pilotUsers = PILOT_USERS_ARRAY.split(',')
  const navigate = useNavigate()

  const handleTextInputChange = (event) => {
    setPurchasedUnits(Number(event.target.value))
  }

  let session = useSessionContext()
  let id = ''
  if (!session.loading) {
    let { userId } = session
    id = userId
  }

  const handleButtonClick = () => {
    window.open(`/payment/history/${id}`, '_blank')
    navigate()
  }
  const MapPerUnitCost = (perUnitCost) => {
    if (perUnitCost <= 21.5) return 1
    else if (21.5 < perUnitCost <= 24.5) return 2
    else if (24.5 < perUnitCost <= 27.5) return 3
    else if (27.5 < perUnitCost <= 32) return 4
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userConfigApiResponse = await axios.get(USER_CONFIG_API + id)
        setPerUnitCost(userConfigApiResponse.data.data.per_unit_cost)
      } catch (error) {
        // Handle error
      }
    }
    fetchData()
  }, [id])

  const callPaymentApi = async () => {
    try {
      const price = perUnitCost * purchasedUnits
      const PriceForGstCalculation = MapPerUnitCost(perUnitCost) * purchasedUnits
      const gst = (GST_PERCENTAGE / 100) * PriceForGstCalculation
      const totalAmount = price + gst + EXTRA_CHARGES
      const paymentPayload = {
        user_id: id,
        units: purchasedUnits,
        price: price,
        per_unit_cost: perUnitCost,
        gst: gst,
        extra_charges: EXTRA_CHARGES,
        total_amount: totalAmount,
      }

      const response = await axios.post(PAYMENT_INITIATE_API, paymentPayload)

      window.location.replace(response.data.data.redirectUrl)
    } catch (error) {
      toast.error('Something went wrong! Please try again...')
    }
  }

  return (
    <Box>
      <Topbar />
      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        minHeight='100vh'
        padding={smScreen ? '30px 0px 100px 0px' : '30px 0px 160px 0px'}
        boxSizing='border-box'
      >
        <img
          src={logo}
          alt='chakr logo'
          width={smScreen ? '150px' : '100px'}
          height={smScreen ? '150px' : '100px'}
        />
        <Box
          display='flex'
          flexDirection='column'
          alignItems='center'
          width={smScreen ? '60%' : '80%'}
          border='2px solid white'
          padding={smScreen ? '40px' : '25px'}
          borderRadius='8px'
          boxShadow='0 4px 8px rgba(0, 0, 0, 0.1)'
          margin='30px 0px'
        >
          <Typography
            variant='h4'
            align='center'
            gutterBottom
            style={{
              marginBottom: '50px',
              letterSpacing: '1.5px',
              lineHeight: smScreen ? '30px' : '22px',
              fontSize: smScreen ? '20px' : '16px',
              padding: smScreen ? '0px 35px' : '0px 15px',
            }}
          >
            Recharge and pay on time to avail seamless, uninterrupted benefits of the Chakr
            Decentralized Energy grid!
          </Typography>

          <TextField
            label='Purchase Electricity units (in kVAh)'
            variant='filled'
            type='text'
            size='normal'
            disabled={!pilotUsers.includes(id)}
            InputProps={{
              type: 'number',
              sx: {
                '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                  display: 'none',
                },
                '& input[type=number]': {
                  MozAppearance: 'textfield',
                },
              },
            }}
            onChange={handleTextInputChange}
            style={{ marginBottom: '15px', width: smScreen ? '30%' : '80%' }}
          />

          <Button
            variant='contained'
            color='success'
            size='large'
            onClick={callPaymentApi}
            style={{ width: smScreen ? '30%' : '80%' }}
          >
            <Typography
              variant='h6'
              align='center'
              fontWeight='bold'
              sx={{ fontSize: smScreen ? '16px' : '18px' }}
            >
              Pay Now
            </Typography>
          </Button>
        </Box>
        <Box
          display='flex'
          flexDirection='column'
          alignItems='center'
          width={smScreen ? '60%' : '80%'}
          marginBottom={smScreen ? '20%' : '30%'}
        >
          <Button
            variant='contained'
            color='success'
            size='large'
            onClick={handleButtonClick}
            style={{ width: smScreen ? '25%' : '60%' }}
          >
            <Typography
              variant='h6'
              align='center'
              fontWeight='bold'
              sx={{ fontSize: smScreen ? '16px' : '18px' }}
            >
              View Payment History
            </Typography>
          </Button>
        </Box>
        <ToastContainer />
      </Box>
    </Box>
  )
}

export default Payment
