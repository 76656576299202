import React, { useEffect, useState } from 'react'
import { Box, Typography } from '@mui/material'
import axios from 'axios'
import { GET_USER_PAYMENT_HISTORY_API } from '../../environment'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { useParams } from 'react-router-dom'
import Topbar from '../global/Topbar'
import { COMPLETED, DRAFT, FAILED, IN_PROGRESS, PENDING } from '../../constants'

const PaymentHistory = () => {
  const param = useParams()
  const id = param.id
  const [userPayments, setUserPayments] = useState(null)
  const indexStyle = {
    cell: {
      fontSize: '16px',
    },
    cellcolor: {
      color: '#ffbb33',
    },
  }
  const handleDate = (convertdate) => {
    if (!convertdate) return '-'
    else {
      const givenTimestamp = convertdate
      const date = new Date(givenTimestamp)
      const istTime = date.toLocaleString('en-US', {
        timeZone: 'Asia/Kolkata',
      })
      return istTime
    }
  }

  useEffect(() => {
    const callPaymentHistoryApi = async () => {
      axios
        .get(GET_USER_PAYMENT_HISTORY_API + id)
        .then((response) => {
          setUserPayments(response.data.data)
        })
        .catch((error) => {
          // console.log(error);
        })
    }
    callPaymentHistoryApi()
  }, [id])
  const showcolor = (status) => {
    let color
    switch (status) {
      case DRAFT:
        color = '#D261F0'
        break
      case IN_PROGRESS:
        color = '#EBE815'
        break
      case PENDING:
        color = '#2AAFF7'
        break
      case COMPLETED:
        color = '#72CE16'
        break
      case FAILED:
        color = '#F94A2B'
        break
      default:
        color = '#ADABAB'
    }

    return <div style={{ color }}>{status}</div>
  }

  return (
    <Box>
      <Topbar />
      <h1 style={{ textAlign: 'center', marginTop: '3%' }}>Payment History</h1>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          textAlign: 'center',
          margin: '50px',
          width: '90%',
        }}
      >
        {!userPayments ? (
          <Typography variant='h3'>
            You have not done any payments till date. Please make a payment to start seeing the
            details here!
          </Typography>
        ) : (
          <Box style={{ padding: '0px 10px' }}>
            <TableContainer component={Paper}>
              <Table
                sx={{
                  minWidth: 650,
                  backgroundColor: '#141b2d',
                  border: '1px solid white',
                }}
                aria-label='simple table'
              >
                <TableHead>
                  <TableRow
                    sx={{
                      flexDirection: 'column !important',
                    }}
                  >
                    <TableCell style={{ ...indexStyle.cell, ...indexStyle.cellcolor }}>
                      Date of Payment
                    </TableCell>
                    <TableCell style={{ ...indexStyle.cell, ...indexStyle.cellcolor }}>
                      Per Unit Price
                    </TableCell>
                    <TableCell style={{ ...indexStyle.cell, ...indexStyle.cellcolor }}>
                      Units Consumed
                    </TableCell>
                    <TableCell style={{ ...indexStyle.cell, ...indexStyle.cellcolor }}>
                      Unit Cost
                    </TableCell>
                    <TableCell style={{ ...indexStyle.cell, ...indexStyle.cellcolor }}>
                      Estimated Tax
                    </TableCell>
                    <TableCell style={{ ...indexStyle.cell, ...indexStyle.cellcolor }}>
                      Total Amount
                    </TableCell>
                    <TableCell style={{ ...indexStyle.cell, ...indexStyle.cellcolor }}>
                      Transaction ID
                    </TableCell>
                    <TableCell style={{ ...indexStyle.cell, ...indexStyle.cellcolor }}>
                      Mode
                    </TableCell>
                    <TableCell style={{ ...indexStyle.cell, ...indexStyle.cellcolor }}>
                      Status
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {userPayments.map((payment, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                        verticalAlign: 'top',
                        flexDirection: 'column',
                      }}
                    >
                      <TableCell style={indexStyle.cell} component='th' scope='row'>
                        {handleDate(payment.created_at)}
                      </TableCell>
                      <TableCell style={indexStyle.cell}>
                        {payment.per_unit_price.toFixed(2)}
                      </TableCell>
                      <TableCell style={indexStyle.cell}>{payment.units}</TableCell>
                      <TableCell style={indexStyle.cell}>{payment.unit_cost.toFixed(2)}</TableCell>
                      <TableCell style={indexStyle.cell}>
                        {payment.estimated_tax.toFixed(2)}
                      </TableCell>
                      <TableCell style={indexStyle.cell}>
                        {payment.total_amount.toFixed(2)}
                      </TableCell>
                      <TableCell style={indexStyle.cell}>{payment.transaction_id}</TableCell>
                      <TableCell style={indexStyle.cell}>{payment.payment_mode}</TableCell>
                      <TableCell style={indexStyle.cell}>
                        {showcolor(payment.status.toUpperCase())}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default PaymentHistory
