import MuiTable from './components/Table'

const showRunningHistory = function (analytics_data, name) {
  const dgArr = []
  const rows = []
  if (analytics_data) {
    for (let j = 0; analytics_data && j < analytics_data.length; j++) {
      const c = analytics_data[j].running_duration
      let runningDuration = Number(String(c)).toFixed(1) * 60
      let duration = ''

      if (Math.floor(runningDuration / 3600)) {
        duration = duration + Math.floor(runningDuration / 3600) + 'h '
      }
      runningDuration = runningDuration % 3600
      if (Math.floor(runningDuration / 60)) {
        duration = duration + Math.floor(runningDuration / 60) + 'm '
      }
      runningDuration = runningDuration % 60
      duration = duration + Math.ceil(runningDuration) + 's '
      const aData = JSON.parse(JSON.stringify(analytics_data[j]))
      aData.running_duration = duration
      rows.push(createData(aData, j + 1))
    }
    if (analytics_data && analytics_data.length > 0) {
      dgArr.push(<MuiTable dg={name} rows={rows} />)
    }
  }
  return dgArr
}

function createData(data, s) {
  return {
    sNo: s,
    date: data.switched_on_at.substr(0, 10),
    startTime: data.switched_on_at.substr(11, 8),
    stopTime: data.switched_off_at.substr(11, 8),
    runningDuration: data.running_duration,
    unitsVah: data.units_kvah.toFixed(2),
  }
}

export default showRunningHistory
