import { Box, Button, TextField } from '@mui/material'
import { Formik } from 'formik'
import * as yup from 'yup'
import useMediaQuery from '@mui/material/useMediaQuery'
import Header from '../../components/Header'
import axios from 'axios'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import React, { useEffect, useState } from 'react'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DateTimePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import { DEVICE_UNREGISTERED, ERROR_MSG, ONBOARD_MAINS_API, USER_ALL_API } from '../../environment'

const MainsForm = () => {
  const isNonMobile = useMediaQuery('(min-width:600px)')
  const [users, setUsers] = useState([])
  const [devices, setDevices] = useState([])
  const [userDropdownOpen, setUserDropdownOpen] = useState(false)
  const [deviceDropdownOpen, setDeviceDropdownOpen] = useState(false)

  useEffect(() => {
    axios
      .get(USER_ALL_API)
      .then((response) => {
        setUsers(response.data.data)
        // console.log("----GET USERS-----", response.data);
      })
      .catch((error) => {
        alert(ERROR_MSG)
      })
  }, [])

  useEffect(() => {
    axios
      .get(DEVICE_UNREGISTERED)
      .then((response) => {
        setDevices(response.data.data)
        // console.log("----GET DEVICES-----", response.data);
      })
      .catch((error) => {
        alert(ERROR_MSG)
      })
  }, [])

  const handleUserDropdownClose = () => {
    setUserDropdownOpen(false)
  }
  const handleUserDropdownOpen = () => {
    setUserDropdownOpen(true)
  }

  const handleDeviceDropdownClose = () => {
    setDeviceDropdownOpen(false)
  }
  const handleDeviceDropdownOpen = () => {
    setDeviceDropdownOpen(true)
  }

  const handleFormSubmit = (values) => {
    const formattedInstallationDate = dayjs(values.installation_date).format('YYYY-MM-DDTHH:mm:ssZ')
    const data = {
      user_id: values.user_id,
      device_id: values.device_id,
      name: values.name,
      installation_date: formattedInstallationDate,
    }

    axios
      .post(ONBOARD_MAINS_API, data)
      .then((response) => {
        // console.log(response);
        alert('Mains onboarded successfully!!!')
      })
      .catch((error) => {
        // console.log(error);
        alert(
          error.response && typeof error.response.data.message === 'string'
            ? error.response.data.message
            : ERROR_MSG,
        )
      })
  }

  return (
    <Box m='50px'>
      <Header title='MAINS Onboarding Form' subtitle='Onboard a new MAINS' />

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display='grid'
              gap='30px'
              gridTemplateColumns='repeat(4, minmax(0, 1fr))'
              sx={{
                '& > div': { gridColumn: isNonMobile ? undefined : 'span 4' },
              }}
            >
              <FormControl variant='filled' sx={{ gridColumn: 'span 2' }}>
                <InputLabel id='simple-select-label'>User Name</InputLabel>
                <Select
                  labelId='simple-select-label'
                  id='simple-select'
                  name='user_id'
                  value={values.user_id}
                  label='User Name'
                  onChange={handleChange}
                  open={userDropdownOpen}
                  onClose={handleUserDropdownClose}
                  onOpen={handleUserDropdownOpen}
                  required
                >
                  {users && Object.entries(users).length > 0 ? (
                    users.map((user) => (
                      <MenuItem key={user.user_id} value={user.user_id}>
                        {user.name ? user.name : user.user_id}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem value=''>None</MenuItem>
                  )}
                </Select>
              </FormControl>
              <FormControl variant='filled' sx={{ gridColumn: 'span 2' }}>
                <InputLabel id='simple-select-label'>Device ID</InputLabel>
                <Select
                  labelId='simple-select-label'
                  id='simple-select'
                  name='device_id'
                  value={values.device_id}
                  label='Device ID'
                  onChange={handleChange}
                  open={deviceDropdownOpen}
                  onClose={handleDeviceDropdownClose}
                  onOpen={handleDeviceDropdownOpen}
                  required
                >
                  {devices && Object.entries(devices).length > 0 ? (
                    devices.map((device) => (
                      <MenuItem key={device.device_id} value={device.device_id}>
                        {device.device_id}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem value=''>None</MenuItem>
                  )}
                </Select>
              </FormControl>
              <TextField
                fullWidth
                variant='filled'
                type='text'
                label='Name'
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.name}
                name='name'
                error={!!touched.name && !!errors.name}
                helperText={touched.name && errors.name}
                sx={{ gridColumn: 'span 2' }}
              />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  label='Installation date'
                  inputFormat='DD/MM/YYYY HH:mm'
                  value={values.installation_date || ''}
                  onChange={(date) => setFieldValue('installation_date', date)}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      variant='filled'
                      {...params}
                      error={touched.installation_date && errors.installation_date}
                      helperText={touched.installation_date && errors.installation_date}
                    />
                  )}
                  sx={{ gridColumn: 'span 2' }}
                />
              </LocalizationProvider>
            </Box>
            <Box display='flex' justifyContent='center' mt='80px'>
              <Button type='submit' color='secondary' variant='contained' size='large'>
                SUBMIT
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  )
}

const checkoutSchema = yup.object().shape({
  user_id: yup.string().required('required'),
  device_id: yup.string().required('required'),
  name: yup.string().required('required'),
  installation_date: yup.date().required('required'),
})

const initialValues = {
  user_id: '',
  device_id: '',
  name: '',
}

export default MainsForm
