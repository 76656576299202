// docs https://github.com/azouaoui-med/react-pro-sidebar
import { useState, useEffect } from 'react'
import { Menu, Sidebar, MenuItem, useProSidebar } from 'react-pro-sidebar'
import { useSidebarContext } from './sidebarContext'
import { tokens } from '../../../theme'
import { useTheme, Box, Typography, IconButton } from '@mui/material'
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined'
import axios from 'axios'
import { GET_DG_OF_USER_API, GET_MAINS_OF_USER_API, USER_API } from '../../../environment'
import { useSessionContext } from 'supertokens-auth-react/recipe/session'
import Button from '@mui/material/Button'

const ProSidebar = () => {
  const theme = useTheme()
  const colors = tokens(theme.palette.mode)
  const { sidebarRTL, sidebarImage } = useSidebarContext()
  const { collapseSidebar, toggleSidebar, collapsed, broken } = useProSidebar()
  const [dgs, setDgs] = useState([])
  const [mains, setMains] = useState([])
  const [appendedMains, setAppendedMains] = useState(false)
  const [vendor, setVendor] = useState({})

  let session = useSessionContext()
  let id = ''
  // console.log(deviceId);
  if (!session.loading) {
    let { userId } = session
    id = userId
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(GET_DG_OF_USER_API + id)
        setDgs(res.data.data)

        const resMains = await axios.get(GET_MAINS_OF_USER_API + id)
        setMains(resMains.data.data)

        setAppendedMains(true)
      } catch (error) {
        // Handle error
      }
    }

    fetchData()
  }, [id, appendedMains])

  useEffect(() => {
    axios
      .get(USER_API + '/' + id)
      .then((response) => {
        setVendor(response.data.data)
        // console.log("---USER DATA----", userData);
      })
      .catch((error) => {
        // alert(process.env.REACT_APP_ERROR_MSG);
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  return (
    <Box
      sx={{
        position: 'sticky',
        display: 'flex',
        height: '100vh',
        top: 0,
        bottom: 0,
        zIndex: 10000,
        '& .sidebar': {
          border: 'none',
        },
        '& .menu-icon': {
          backgroundColor: 'transparent !important',
        },
        '& .menu-item': {
          // padding: "5px 35px 5px 20px !important",
          backgroundColor: 'transparent !important',
        },
        '& .menu-anchor': {
          color: 'inherit !important',
          backgroundColor: 'transparent !important',
        },
        '& .menu-item:hover': {
          color: `${colors.blueAccent[500]} !important`,
          backgroundColor: 'transparent !important',
        },
        '& .menu-item.active': {
          color: `${colors.greenAccent[500]} !important`,
          backgroundColor: 'transparent !important',
        },
      }}
    >
      <Sidebar
        breakPoint='md'
        rtl={sidebarRTL}
        backgroundColor={colors.primary[400]}
        image={sidebarImage}
      >
        <Menu iconshape='square'>
          <MenuItem
            icon={collapsed ? <MenuOutlinedIcon onClick={() => collapseSidebar()} /> : undefined}
            style={{
              margin: '10px 0 20px 0',
              color: colors.grey[100],
            }}
          >
            {!collapsed && (
              <Box display='flex' justifyContent='space-between' alignItems='center' ml='40px'>
                <Typography variant='h3' color={colors.greenAccent[500]}>
                  CHAKR DE
                </Typography>
                <IconButton onClick={broken ? () => toggleSidebar() : () => collapseSidebar()}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>
          {!collapsed && (
            <Box mb='25px'>
              <Box textAlign='center' alignItems='center'>
                <Typography
                  variant='h3'
                  color={colors.grey[100]}
                  fontWeight='bold'
                  sx={{ m: '20px 0 0 0' }}
                >
                  {vendor ? vendor.name : 'Loading...'}
                  <br></br>
                  <br></br>
                  {dgs ? 'DG' : ''}
                  {dgs ? dgList(dgs, colors) : ''}
                  <br></br>
                  {mains ? 'MAINS' : ''}
                  {mains ? mainsList(mains, colors) : ''}
                </Typography>
              </Box>
            </Box>
          )}
        </Menu>
        {!collapsed && (
          <Box mb='25px' backgroundColor={colors.blueAccent[700]}>
            <Box textAlign='center' alignItems='center'>
              <Typography variant='h5' color={colors.grey[100]} sx={{ m: '310% 0 0 0' }}>
                Chakr DE is owned/part of Chakr Innovation Pvt Ltd
              </Typography>
            </Box>
          </Box>
        )}
      </Sidebar>
    </Box>
  )
}

function dgList(data, colors) {
  return (
    <Box>
      <ul style={{ listStyleType: 'none' }}>
        {data.map((dg, idx) => (
          <li key={dg.device_id}>
            <Button
              sx={{ marginRight: '15%', borderRadius: '16px', marginTop: '7%' }}
              size='large'
              href={'/dg/' + dg.device_id}
              variant='contained'
            >
              {' '}
              <Typography variant='h5' color='#FAC898' fontWeight='bold' align='center'>
                {dg.name}
              </Typography>{' '}
            </Button>
          </li>
        ))}
      </ul>
    </Box>
  )
}

function mainsList(data, colors) {
  return (
    <Box>
      <ul style={{ listStyleType: 'none' }}>
        {data.map((main, idx) => (
          <li key={main.device_id}>
            <Button
              sx={{ marginRight: '15%', borderRadius: '16px', marginTop: '7%' }}
              size='large'
              href={'/main/' + main.device_id}
              variant='contained'
            >
              {' '}
              <Typography variant='h5' color='#FAC898' fontWeight='bold' align='center'>
                {main.name}
              </Typography>{' '}
            </Button>
          </li>
        ))}
      </ul>
    </Box>
  )
}

export default ProSidebar
