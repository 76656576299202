import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Bar } from 'react-chartjs-2'
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js'
import { GET_DG_LOAD_PROFILE } from '../../environment.js'
import Button from '@mui/material/Button'
import { styled } from '@mui/system'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

const CustomButton = styled(Button)`
  color: black;
  border: 2px solid grey;
  font-weight: bold;
  font-size: 90%;
  height: 30px;
  border-radius: 20px;
  background-color: #d3d3d3;
  margin-top: 3%;
  margin-left: 1%;
  &:hover {
    background-color: #ffac1c;
  }
`

const LoadProfile = ({ deviceId }) => {
  const [graphData, setGraphData] = useState(null)
  const [timeScale, setTimeScale] = useState('1w')
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true)
        if (deviceId) {
          const response = await axios.get(GET_DG_LOAD_PROFILE, {
            params: {
              deviceId,
              unit: timeScale,
            },
          })
          setGraphData(response.data.data)
        }
      } catch (error) {
        // console.error(error);
      } finally {
        setIsLoading(false)
      }
    }

    fetchData()
  }, [deviceId, timeScale])

  const renderBarGraph = () => {
    if (!graphData) {
      return null
    }
    const xData = graphData.x_data
    const yData = graphData.y_data
    let labels
    if (timeScale === '1h') {
      labels = xData.map((dateString) => {
        const date = new Date(dateString)
        return `${date.getHours()}:00`
      })
    } else {
      labels = xData.map((dateString) => {
        const date = new Date(dateString)
        const options = { month: 'short', day: 'numeric' }
        return date.toLocaleDateString('en-US', options)
      })
    }

    const kwhData = yData.map((entry) => parseFloat(entry.KWh))
    const kvah = yData.map((entry) => parseFloat(entry.kVAh))
    const pf = yData.map((entry) => parseFloat(entry.PF))

    const data = {
      labels,
      datasets: [
        {
          label: 'kWh',
          data: kwhData,
          backgroundColor: 'rgba(255, 207, 28, 0.5)',
        },
        {
          label: 'kVAh',
          data: kvah,
          backgroundColor: 'rgba(214, 255, 28, 0.5)',
        },
        {
          label: 'pf',
          data: pf,
          backgroundColor: 'rgba(28, 255, 227, 0.5)',
        },
      ],
    }

    const options = {
      indexAxis: 'x',
      layout: {
        padding: {
          bottom: 10,
        },
      },
      plugins: {
        legend: {
          position: 'right',
          labels: {
            color: 'white',
            font: {
              size: 15,
            },
          },
          display: false,
        },
      },
      scales: {
        y: {
          stacked: false,
          beginAtZero: true,
          ticks: {
            display: true,
            color: 'white',
          },
        },
        x: {
          stacked: false,
          type: 'category',
          ticks: {
            display: true,
            color: 'white',
          },
        },
      },
      responsive: true,
      interaction: {
        mode: 'index',
        intersect: false,
      },
    }
    return <Bar data={data} options={options} />
  }

  function timeFrame(unit) {
    setTimeScale(unit)
  }

  return (
    <div key={deviceId}>
      <CustomButton onClick={() => timeFrame('1h')} value={'1h'} disabled={isLoading}>
        Hourly
      </CustomButton>
      <CustomButton onClick={() => timeFrame('1d')} value={'1d'} disabled={isLoading}>
        Daily
      </CustomButton>
      <CustomButton onClick={() => timeFrame('1w')} value={'1w'} disabled={isLoading}>
        Weekly
      </CustomButton>
      <div style={{ paddingBottom: '3%' }}></div>
      {renderBarGraph()}
    </div>
  )
}

export default LoadProfile
