import { Box, Button, TextField } from '@mui/material'
import { Formik } from 'formik'
import * as yup from 'yup'
import useMediaQuery from '@mui/material/useMediaQuery'
import Header from '../../components/Header'
import axios from 'axios'
import React from 'react'
import { ERROR_MSG, WHATSAPP_OPT_IN_API } from '../../environment'

const OptInForm = () => {
  const isNonMobile = useMediaQuery('(min-width:600px)')

  const handleFormSubmit = (values) => {
    // console.log(values);
    axios
      .post(WHATSAPP_OPT_IN_API, values)
      .then((response) => {
        // console.log(response);
        alert('Opt-in request sent to user successfully!!')
      })
      .catch((error) => {
        // console.log(error);
        alert(ERROR_MSG)
      })
  }

  return (
    <Box m='50px'>
      <Header title='Whatsapp Opt-in Form' subtitle='Enter Whatsapp Number for Opt-in request' />

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
        validate={validate}
      >
        {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display='grid'
              gap='30px'
              gridTemplateColumns='repeat(4, minmax(0, 1fr))'
              sx={{
                '& > div': { gridColumn: isNonMobile ? undefined : 'span 4' },
              }}
            >
              <TextField
                fullWidth
                variant='filled'
                type='text'
                label='Phone'
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.phone}
                name='phone'
                error={!!touched.phone && !!errors.phone}
                helperText={touched.phone && errors.phone}
                sx={{ gridColumn: 'span 4', alignContent: 'center' }}
              />
            </Box>
            <Box display='flex' justifyContent='center' mt='80px'>
              <Button type='submit' color='secondary' variant='contained' size='large'>
                SUBMIT
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  )
}

const checkoutSchema = yup.object().shape({
  phone: yup.number().required('required'),
})
const initialValues = {
  phone: '',
}

const validate = (values, props) => {
  const errors = {}
  var phoneExp = /^[6-9][0-9]{9}$/
  if (!phoneExp.test(values.phone)) {
    errors.phone = 'Not a valid number'
  }
  return errors
}

export default OptInForm
