import ReactSpeedometer from 'react-d3-speedometer'
import React from 'react'
import { useTheme, useMediaQuery } from '@mui/material'

const Speedometer = ({ data }) => {
  const theme = useTheme()
  const smScreen = useMediaQuery(theme.breakpoints.down('sm'))

  let runingHours = 0
  for (let i = 0; i < data.length; i++) {
    runingHours += data[i].running_duration
  }
  return (
    <ReactSpeedometer
      width={smScreen ? 350 : 500}
      needleHeightRatio={smScreen ? 0.6 : 0.7}
      value={runingHours / 60}
      customSegmentStops={[0, 250, 300, 322.58]}
      minValue={0}
      maxValue={322.58}
      segmentColors={['#00FF00', '#FFFF00', '#FF0000']}
      currentValueText='Maintenance needed?'
      customSegmentLabels={[
        {
          text: smScreen ? '' : 'Not needed',
          position: 'OUTSIDE',
          color: '#d8dee9',
        },
        {
          text: smScreen ? '' : 'Due soon',
          position: 'OUTSIDE',
          color: '#d8dee9',
        },
        {
          text: smScreen ? '' : 'Overdue',
          position: 'OUTSIDE',
          color: '#d8dee9',
        },
      ]}
      ringWidth={47}
      needleTransitionDuration={3333}
      needleTransition='easeElastic'
      needleColor={'#89CFF0'}
      textColor={'#d8dee9'}
    />
  )
}

export default Speedometer
